import axios from "axios";
import { BASE_URL, REMOTE_URL } from "./type";

export function getStations(params) {
  return axios.get(BASE_URL + "stations", { params });
}
export function getStationsByUser(params) {
  return axios.get(BASE_URL + "stationsByUser", { params });
}

export function getStationsById(id, params) {
  return axios.get(BASE_URL + "stations/" + id, { params });
}

export function getLogs(id, params) {
  return axios.get(BASE_URL + "log/" + id, {params});
}

export function createStation(data) {
  return axios.post(BASE_URL + "stations", data);
}
export function updateStation(id, data) {
  return axios.put(BASE_URL + "stations/" + id, data);
}
export function updateStationNotes(id, data) {
  return axios.put(`${BASE_URL}stations/${id}/notes`, data);
}
export function deleteStationById(id) {
  return axios.delete(BASE_URL + "stations/" + id);
}
export function getDashboardStations() {
  return axios.get(BASE_URL + "dashboard/stations");
}
export function RemoteReset(stationId, resetBody) {
  return axios.post(REMOTE_URL + "reset/" + stationId, { type: resetBody });
}

export function updateFirmware(stationId, updateBody) {
  return axios.post(REMOTE_URL + "updateFirmware/" + stationId, updateBody);
}

export function heartbeat() {
  return axios.get(REMOTE_URL + "heartbeat");
}

export { REMOTE_URL };
