// @flow

import * as React from "react";
import { useState, useEffect, useFocus, useRef } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import SiteWrapper from "../SiteWrapper";
import * as userService from "../store/services/userService";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
const formSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    // .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
});

function AccountPage(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [isEdit, setEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const handleSubmit = (values) => {
    let data = {
      name: values.firstName + " " + values.lastName,
      email: values.email,
      password: values.password || undefined,
    };
    setLoading(true);
    userService
      .updateOwnAccount(data)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        window.dispatchEvent(new Event("storage"));
        setLoading(false);
        setAlert({
          type: "success",
          message: "Successfully updated changed data",
        });
      })
      .catch((error) => {
        setAlert({
          type: "danger",
          message:
            (error.response && error.response.data.message) || error.message,
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    userService
      .getOwnAccount()
      .then((response) => {
        let values = response.data;

        setInitialValues({
          id: values.id,
          firstName: values.name.split(" ")[0],
          lastName: values.name.split(" ")[1],
          email: values.email,
          password: "",
          password_confirmation: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message:
            (error.response && error.response.data.message) || error.message,
        });
      });
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        if (values.password === values.password_confirmation) {
          handleSubmit(values);
        } else {
          setErrors({ password_confirmation: "Password must match" });
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset,
      }) => (
        <SiteWrapper loading={loading}>
          <Page.Content>
            <Page.Header title="My Account" />
            <Grid.Row cards={true}>
              <Grid.Col width={12}>
                <Card className="charge-point-card">
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className="m-auto">
                      <Card.Header className="d-flex justify-content-between">
                        <Card.Title>Account Details</Card.Title>
                        <div className="ml-auto lh-1">
                          {!isEdit && (
                            <Button
                              color="secondary"
                              onClick={() => {
                                setEdit(true);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                          {isEdit && (
                            <Button
                              color="secondary"
                              onClick={() => {
                                setEdit(false);
                                handleReset();
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </Card.Header>
                    </Grid.Col>
                    <Grid.Col width={12} lg={12}>
                      <div className="border-bottom"></div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className="m-auto">
                      <Card.Body>
                        <form
                          onSubmit={handleSubmit}
                          autoComplete="off"
                          className="mt-5"
                        >
                          <Grid.Row>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="firstName"
                                  disabled={!isEdit}
                                  className={
                                    errors.firstName && touched.firstName
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="First Name"
                                />
                                {errors.firstName && touched.firstName && (
                                  <div className="invalid-feedback">
                                    {errors.firstName}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="lastName"
                                  disabled={!isEdit}
                                  className={
                                    errors.lastName && touched.lastName
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Last Name"
                                />
                                {errors.lastName && touched.lastName && (
                                  <div className="invalid-feedback">
                                    {errors.lastName}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="email"
                                  disabled={!isEdit}
                                  className={
                                    errors.email && touched.email
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Email"
                                />
                                {errors.email && touched.email && (
                                  <div className="invalid-feedback">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="password"
                                  type="password"
                                  disabled={!isEdit}
                                  className={
                                    errors.password && touched.password
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="*********"
                                />
                                {errors.password && touched.password && (
                                  <div className="invalid-feedback">
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            {isEdit && (
                              <Grid.Col width={12} md={6}>
                                <div className="form-group">
                                  <Field
                                    name="password_confirmation"
                                    type="password"
                                    className={
                                      errors.password_confirmation &&
                                      touched.password_confirmation
                                        ? "form-control is-invalid state-invalid"
                                        : "form-control"
                                    }
                                    placeholder="Confirm new password"
                                  />
                                  {errors.password_confirmation &&
                                    touched.password_confirmation && (
                                      <div className="invalid-feedback">
                                        {errors.password_confirmation}
                                      </div>
                                    )}
                                </div>
                              </Grid.Col>
                            )}
                          </Grid.Row>

                          {alert.message && (
                            <Alert type={alert.type}>{alert.message}</Alert>
                          )}
                          {isEdit && (
                            <button
                              type="submit"
                              className="btn btn-barlows mt-5 mb-4"
                              disabled={loading}
                            >
                              Save Change
                            </button>
                          )}
                        </form>
                      </Card.Body>
                    </Grid.Col>
                  </Grid.Row>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </SiteWrapper>
      )}
    />
  );
}

export default AccountPage;
