import * as React from "react";
import { useState, useEffect, useFocus, useRef } from "react";
import { NavDropdown } from "react-bootstrap";
import { Grid, Card, Progress } from "tabler-react";
import ReactApexChart from "react-apexcharts";
import * as uptimeServicee from "../store/services/uptimeServices";
import Svgicon from "./SvgIcon";

function UptimeCard(props) {
  const [percentType, setPercentType] = useState("Last 7 days");
  const [uptimeParams, setUptimeParams] = useState({
    period: 7,
  });
  const [viewType, setViewType] = useState("Chart");
  const [percentData, setReportData] = useState(0);
  const [totalPercentData, setTotalReportData] = useState(0);
  const [chartOptions, setChart] = useState({
    options: {
      chart: {
        type: "bar",
        fontFamily: "inherit",
        height: 240,
        parentHeightOffset: 0,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        stacked: true,
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          top: -20,
          right: 0,
          left: -4,
          bottom: -4,
        },
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        labels: {
          padding: 0,
          style: {
            colors: "#7c8d85",
            // colors: ["#7c8d85", "red"],
            fontSize: "11px",
            cssClass: "charts-label",
          },
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        max: 100,
        tickAmount: 5,
        labels: {
          padding: 4,
          style: {
            colors: "#7c8d85",
            // colors: ["#7c8d85", "red"],
            fontSize: "11px",
            cssClass: "charts-label",
          },
        },
      },
      labels: [
        "2020-06-20",
        "2020-06-21",
        "2020-06-22",
        "2020-06-23",
        "2020-06-24",
        "2020-06-25",
        "2020-06-26",
        "2020-06-27",
        "2020-06-28",
        "2020-06-29",
        "2020-06-30",
        "2020-07-01",
        "2020-07-02",
        "2020-07-03",
        "2020-07-04",
        "2020-07-05",
        "2020-07-06",
        "2020-07-07",
        "2020-07-08",
        "2020-07-09",
        "2020-07-10",
        "2020-07-11",
        "2020-07-12",
        "2020-07-13",
        "2020-07-14",
        "2020-07-15",
        "2020-07-16",
        "2020-07-17",
        "2020-07-18",
        "2020-07-19",
        "2020-07-20",
        "2020-07-21",
        "2020-07-22",
        "2020-07-23",
        "2020-07-24",
        "2020-07-25",
        "2020-07-26",
      ],
      colors: ["#000000"],
      legend: {
        show: true,
        position: "bottom",
        height: 32,
        offsetY: 8,
        markers: {
          width: 8,
          height: 8,
          radius: 100,
        },
        itemMargin: {
          horizontal: 8,
        },
      },
    },
    series: [
      {
        name: "Charge Points",
        data: [],
      },
    ],
  });
  const changePercentType = (type) => {
    setPercentType(type);
  };

  useEffect(() => {
    uptimeServicee
      .getUptimeReports(uptimeParams, props.id)
      .then((response) => {
        setReportData(response.data.period_percent);
        setTotalReportData(response.data.ratio_percent);
        let xaxis = [];
        let yaxis = [];
        response.data.chart_data.map((item) => {
          xaxis.push(item.date);
          yaxis.push(
            item.total_count
              ? Math.floor((item.up_count / item.total_count) * 100)
              : 0
          );
        });
        setChart({
          options: {
            chart: {
              type: "bar",
              fontFamily: "inherit",
              height: 240,
              parentHeightOffset: 0,
              toolbar: {
                show: false,
              },
              animations: {
                enabled: false,
              },
              stacked: true,
            },
            plotOptions: {
              bar: {
                columnWidth: "50%",
              },
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 1,
            },
            grid: {
              padding: {
                top: -20,
                right: 0,
                left: -4,
                bottom: -4,
              },
              strokeDashArray: 4,
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              labels: {
                padding: 0,
                style: {
                  colors: "#7c8d85",
                  // colors: ["#7c8d85", "red"],
                  fontSize: "11px",
                  cssClass: "charts-label",
                },
              },
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: false,
              },
              type: "datetime",
            },
            yaxis: {
              max: 100,
              tickAmount: 5,
              labels: {
                padding: 4,
                style: {
                  colors: "#7c8d85",
                  // colors: ["#7c8d85", "red"],
                  fontSize: "11px",
                  cssClass: "charts-label",
                },
              },
            },
            labels: xaxis,
            colors: ["#000000"],
            legend: {
              show: true,
              position: "bottom",
              height: 32,
              offsetY: 8,
              markers: {
                width: 8,
                height: 8,
                radius: 100,
              },
              itemMargin: {
                horizontal: 8,
              },
            },
          },
          series: [
            {
              name: "Charge Points",
              data: yaxis,
            },
          ],
        });
      })
      .catch((error) => { });
  }, [uptimeParams]);

  return (
    <Grid.Col width={12} lg={6}>
      <Card className="h-100">
        <Card.Header className="d-flex justify-content-between">
          <Svgicon name='uptime'/>
          <Card.Title>Uptime</Card.Title>
          <div className="ml-auto lh-1">
            <NavDropdown alignRight muted title={viewType + " View"}>
              <NavDropdown.Item
                onClick={() => {
                  setViewType("Chart");
                }}
                active={viewType === "Chart"}
              >
                Chart View
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  setViewType("Percentage");
                }}
                active={viewType === "Percentage"}
              >
                Percentage View
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Card.Header>
        <Card.Body>
          {viewType === "Chart" && (
            <ReactApexChart
              options={chartOptions.options}
              series={chartOptions.series}
              type="bar"
              height={182}
            ></ReactApexChart>
          )}
          {viewType === "Percentage" && (
            <>
              {/* <div className="lh-1 mb-4">
                <NavDropdown alignRight muted title={percentType}>
                  <NavDropdown.Item
                    onClick={() => {
                      changePercentType("Last 7 days");
                      setUptimeParams({ period: 7 });
                    }}
                  >
                    Last 7 days
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      changePercentType("Last 30 days");
                      setUptimeParams({ period: 30 });
                    }}
                  >
                    Last 30 days
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      changePercentType("Last 3 months");
                      setUptimeParams({ period: 90 });
                    }}
                  >
                    Last 3 months
                  </NavDropdown.Item>
                </NavDropdown>
              </div> */}
              <div className="d-flex mb-2 align-items-baseline mtm-20">
                <div className="h1 mb-3">{Math.floor(percentData)}%</div>
                {!props.nopercent &&
                  <div className="ml-2">
                    {totalPercentData > 0 && (
                      <span className="text-green d-inline-flex align-items-center lh-1">
                        {Math.floor(totalPercentData)}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-640.000000, -207.000000)"
                              stroke="#000000"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(530.000000, 148.000000)">
                                <g transform="translate(88.000000, 55.000000)">
                                  <g transform="translate(24.000000, 5.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                    {totalPercentData < 0 && (
                      <span className="text-red d-inline-flex align-items-center lh-1">
                        {Math.floor(Math.abs(totalPercentData))}%
                        {/* {Math.abs(totalPercentData).toFixed(0)}% Math.floor */}
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-1018.000000, -282.000000)"
                              stroke="#FF4747"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(930.000000, 222.000000)">
                                <g transform="translate(66.000000, 55.000000)">
                                  <g transform="translate(32.500000, 12.000000) scale(1, -1) translate(-32.500000, -12.000000) translate(24.000000, 6.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                  </div>}
              </div>
              <Progress size="xs">
                <Progress.Bar color="green" width={percentData} />
              </Progress>
            </>
          )}
        </Card.Body>
      </Card>
    </Grid.Col>
  );
}

export default UptimeCard;
