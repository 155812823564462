import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import SiteWrapper from "../../SiteWrapper";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import * as StripeTransactionService from "../../store/services/stripeTransactionsService";
import StripePaymentNav from "./StripePaymentNav";
import Svgicon from "../../components/SvgIcon";
function UpdateTransactionPage(props) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [transaction, setTransaction] = useState({});
  const handleRefund = () => {
    setLoading(true);
    StripeTransactionService.createRefund({ charge: transaction.source })
      .then((response) => {
        setLoading(false);
        setAlert({
          type: "success",
          message: "Successfully created refund.",
        });
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
      });
  };

  useEffect(() => {
    setPageLoading(true);
    StripeTransactionService.getTransactionById(props.match.params.id)
      .then((response) => {
        setPageLoading(false);
        setTransaction(response.data.transaction);
      })
      .catch((error) => {
        setPageLoading(false);
      });
  }, []);

  return (
    <SiteWrapper loading={pageLoading}>
      <StripePaymentNav />
      <Page.Content>
        <Page.Header title='Transactions' />
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card className='charge-point-card'>
              <Grid.Row>
                <Grid.Col width={12} lg={8} className='m-auto'>
                  <Card.Header>
                    <Svgicon name='transaction' />
                    <Card.Title>Transactions Details</Card.Title>
                    <div className="ml-auto lh-1">
                      <Button
                        color="secondary"
                        onClick={() => props.history.push("/payments/transactions")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card.Header>
                </Grid.Col>
                <Grid.Col width={12} lg={12}>
                  <div className='border-bottom'></div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12} lg={8} className='m-auto'>
                  <Card.Body className='pt-40 pb-40'>
                    <Grid.Row>
                      <Grid.Col className='transaction-details'>
                        <div className='subheader mb-3'>ID</div>
                        <div className='details'>{transaction.id}</div>
                      </Grid.Col>
                      <Grid.Col className='transaction-details'>
                        <div className='subheader mb-3'>DATE</div>
                        <div className='details'>
                          {transaction.created && (
                            <Moment unix format='DD MMM YYYY'>
                              {transaction.created}
                            </Moment>
                          )}
                        </div>
                      </Grid.Col>
                      {/* <Grid.Col>
                        <div className="subheader mb-3">CUSTOMER NAME</div>
                        <div className="details"></div>
                      </Grid.Col> */}
                      {/* <Grid.Col>
                        <div className="subheader mb-3">CUSTOMER EMAIL</div>
                        <div className="details"></div>
                      </Grid.Col> */}
                      <Grid.Col className='transaction-details'>
                        <div className='subheader mb-3'>STATUS</div>
                        <div className='details'>{transaction.status}</div>
                      </Grid.Col>

                      {/* </Grid.Row>
                    <Grid.Row className='mb-5'> */}
                      <Grid.Col className='transaction-details'>
                        <div className='subheader mb-3'>TYPE</div>
                        <div className='details'>{transaction.type}</div>
                      </Grid.Col>
                      <Grid.Col className='transaction-details'>
                        <div className='subheader mb-3'>CHARGE ID</div>
                        <div className='details' style={{ marginBottom: -10 }}>{transaction.source}</div>
                      </Grid.Col>
                      <Grid.Col className='transaction-details'>
                        <div className='subheader mb-3'>NET AMOUNT</div>
                        <div className='details' style={{ marginBottom: -10 }}>{transaction.net && "£" + (transaction.net / 100).toFixed(2)}</div>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                </Grid.Col>
              </Grid.Row>
              {transaction.type === "charge" && (
                <Grid.Row>
                  <Grid.Col width={12} lg={12}>
                    <div className='border-bottom'></div>
                  </Grid.Col>
                  <Grid.Col width={12} lg={8} className='m-auto'>
                    <Card.Header className='d-flex justify-content-between'>
                      <Card.Title>Refund Transaction</Card.Title>
                    </Card.Header>
                  </Grid.Col>
                  <Grid.Col width={12} lg={12}>
                    <div className='border-bottom'></div>
                  </Grid.Col>
                  <Grid.Col width={12} lg={8} className='m-auto'>
                    <Card.Body className='pt-40'>
                      <div className='pb-40' style={{ color: '#74797E' }}>
                        If you need to refund the transaction amount back to the customers payment method then you can do so by clicking the button
                        below. Please note that refunding the transaction cannot be undone.
                      </div>
                      <div className='mb-5'>
                        {alert.message && <Alert type={alert.type}>{alert.message}</Alert>}

                        <Button color='barlows' onClick={handleRefund} disabled={isLoading}>
                          Refund
                        </Button>
                      </div>
                    </Card.Body>
                  </Grid.Col>
                </Grid.Row>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default UpdateTransactionPage;
