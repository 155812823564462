import * as React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from "./store/history";
import {
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
  Empty,
  Email,
  ProfilePage,
  VerifyEmailPage,
  ResetPasswordPage,
  ChargePointsPage,
  AddNewChargePoint,
  SingleChargePoint,
  CompaniesPage,
  ReportsPage,
  SettingsPage,
  UsersPage,
  AddNewUser,
  SingleUser,
  InviteUserPage,
  AddComany,
  SingleCompany,
  SitesPage,
  AddNewSite,
  SingleSite,
  AccountPage,
  AssignCPToUser,
  BillingPage,
  UpdateDisputes,
  UpdateTransactionPage,
  AddNewPayment,
} from "./pages";

import HomePage from "./HomePage.react";
import FormElementsPage from "./FormElementsPage.react";
import PricingCardsPage from "./interface/PricingCardsPage.react";
import CardsDesignPage from "./interface/CardsDesignPage.react";
import StoreCardsPage from "./components/StoreCardsPage.react.js";
import IconPage from "./components/IconPage.react.js";
import ChartsPage from "./interface/ChartsPage.react";
import GalleryPage from "./GalleryPage.react";
import MapCardsPage from "./components/MapCardsPage.react";
import BlogPage from "./components/BlogPage.react";
import { PrivateRoute } from "./PrivateRoute";
import "tabler-react/dist/Tabler.css";
import StripePaymentsIndex from "./pages/StripePayments";
import DisputesPage from "./pages/StripePayments/DisputesPage";
import { EditPlan } from "./pages/Billing";
import PaymentSettings from "./pages/StripePayments/Settings";
function App(props) {
  return (
    // <React.StrictMode>
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path='/' component={HomePage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/charge-points' component={ChargePointsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/charge-points/new' component={AddNewChargePoint} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/charge-points/:id' component={SingleChargePoint} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/charge-points/edit/:id' component={AddNewChargePoint} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/companies' component={CompaniesPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/new' component={AddComany} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/edit/:id' component={AddComany} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/:id' component={SingleCompany} roles={["Super", "Admin", "Manager"]} />
        {/* <PrivateRoute
          exact
          path="/companies/:companyId/sites"
          component={SitesPage}
          roles={["Super", "Admin", "Manager"]}
        /> */}
        <PrivateRoute exact path='/companies/:companyId/sites/new' component={AddNewSite} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/:companyId/sites/edit/:id' component={AddNewSite} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/:companyId/sites/:id' component={SingleSite} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/reports' component={ReportsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/users' component={UsersPage} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/new' component={AddNewUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/assign/:id' component={AssignCPToUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/assign/:id/:customId' component={AssignCPToUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/:id' component={SingleUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/edit/:id' component={AddNewUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/accounts' component={AccountPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/billing' component={BillingPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/400' component={Error400} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/401' component={Error401} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/403' component={Error403} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/404' component={Error404} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/500' component={Error500} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/503' component={Error503} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/blog' component={BlogPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/cards' component={CardsDesignPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/charts' component={ChartsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/email' component={Email} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/empty-page' component={Empty} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/form-elements' component={FormElementsPage} roles={["Super", "Admin", "Manager"]} />
        <Route exact path='/forgot-password' component={ForgotPasswordPage} />
        <Route exact path='/reset-password/:id' component={ResetPasswordPage} />
        <Route exact path='/verify-email' component={VerifyEmailPage} />
        <Route exact path='/invite' component={InviteUserPage} />
        <PrivateRoute exact path='/gallery' component={GalleryPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/icons' component={IconPage} roles={["Super", "Admin", "Manager"]} />
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute exact path='/maps' component={MapCardsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/pricing-cards' component={PricingCardsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/profile' component={ProfilePage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/store' component={StoreCardsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/payments' component={StripePaymentsIndex} roles={["Admin"]}>
          <Redirect to='/payments/transactions' />
        </PrivateRoute>
        <PrivateRoute exact path='/payments/transactions' component={StripePaymentsIndex} roles={["Admin"]} />
        <PrivateRoute exact path='/payments/disputes' component={DisputesPage} roles={["Admin"]} />
        <PrivateRoute exact path='/payments/settings' component={PaymentSettings} roles={["Admin"]} />
        <PrivateRoute exact path='/payments/settings/:param' component={PaymentSettings} roles={["Admin"]} />
        <PrivateRoute exact path='/payments/transactions/:id' component={UpdateTransactionPage} roles={["Admin"]} />
        <PrivateRoute exact path='/payments/disputes/:id' component={UpdateDisputes} roles={["Admin"]} />
        <Route exact path='/register' component={RegisterPage} />
        <PrivateRoute exact path='/companies/:companyId/sites/new' component={AddNewSite} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/:companyId/sites/edit/:id' component={AddNewSite} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/companies/:companyId/sites/:id' component={SingleSite} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/reports' component={ReportsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/settings' component={SettingsPage} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users' component={UsersPage} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/new' component={AddNewUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/assign/:id' component={AssignCPToUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/assign/:id/:customId' component={AssignCPToUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/:id' component={SingleUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users/edit/:id' component={AddNewUser} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/accounts' component={AccountPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/billing' component={BillingPage} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/billing/plan' component={EditPlan} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/payment-method/new' component={AddNewPayment} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/payment-method/new/:id' component={AddNewPayment} roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/400' component={Error400} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/401' component={Error401} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/403' component={Error403} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/404' component={Error404} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/500' component={Error500} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/503' component={Error503} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/blog' component={BlogPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/cards' component={CardsDesignPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/charts' component={ChartsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/email' component={Email} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/empty-page' component={Empty} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/form-elements' component={FormElementsPage} roles={["Super", "Admin", "Manager"]} />
        <Route exact path='/forgot-password' component={ForgotPasswordPage} />
        <Route exact path='/reset-password/:id' component={ResetPasswordPage} />
        <Route exact path='/verify-email' component={VerifyEmailPage} />
        <Route exact path='/invite' component={InviteUserPage} />
        <PrivateRoute exact path='/gallery' component={GalleryPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/icons' component={IconPage} roles={["Super", "Admin", "Manager"]} />
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute exact path='/maps' component={MapCardsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/pricing-cards' component={PricingCardsPage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/profile' component={ProfilePage} roles={["Super", "Admin", "Manager"]} />
        <PrivateRoute exact path='/store' component={StoreCardsPage} roles={["Super", "Admin", "Manager"]} />
        <Route exact path='/register' component={RegisterPage} />
        <Route component={Error404} />
      </Switch>
    </Router>
    // </React.StrictMode>
  );
}

export default App;
