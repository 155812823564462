import React from 'react';
import Select from 'react-styled-select';
import './index.css'

function CustomSelect({ options, placeholder, onChange, value, error, filter }) {
    const myChangeFunc = event => {
        let v = { target: { value: '' } };
        v.target.value = event;
        onChange(event);
    }
    return (
        <Select
            options={options}
            onChange={myChangeFunc}
            classes={{
                selectValue: 'my-custom-value',
                selectArrow: 'my-custom-arrow',
                selectControl: !value && error && error.type ? 'my-custom-input error' : filter ? 'my-custom-input filter' : 'my-custom-input',
                selectMenu: filter ? 'my-custom-menu filter-menu' : 'my-custom-menu',
                selectOption: filter ? 'custom-option filter-option' : 'custom-option',
                selectMenuOuter: 'my-custom-menu'
            }}
            // className='custom-select'
            searchable={false}
            placeholder={placeholder}
            value={value}
        />
    )
}

export default CustomSelect;