import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Page, Grid, Form, Alert } from "tabler-react";
import { NavLink, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as authService from "../store/services/authServices";
import * as Yup from "yup";
import PageFooter from "./PageFooter";
const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});
const SuccessPage = () => {
  const history = useHistory();
  const goToLogin = () => {
    history.push("/login");
  };
  return (
    <Page>
      <div className="login-page">
        <Grid.Row>
          <div className="col-lg-6">
            <Grid.Row>
              <div
                className="col-md-6 col-lg-8 form-body"
                style={{ marginTop: "auto" }}
              >
                <label className="welcome-title">Success!</label>
                <label className="label-title">
                  Check your inbox. We have sent you an email with instructions
                  on how to reset you password.
                </label>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                  autoComplete="off"
                >
                  <button type="submit" className="btn btn-block btn-barlows">
                    Back to Log In
                  </button>
                </form>
              </div>
            </Grid.Row>
          </div>
          {/* <div className="col-lg-6 ">
            <div className="ev-logo password-logo"></div>
          </div> */}
        </Grid.Row>
        <PageFooter />
      </div>
    </Page>
  );
};
function ForgotPasswordPage(props) {
  const [isLoading, setSubmit] = useState(false);
  const [requested, setRequested] = useState(false);
  const [alert, setAlert] = useState({});
  const handleForgotPassword = (data) => {
    setSubmit(true);
    authService
      .forgotPassword(data)
      .then((res) => {
        setSubmit(false);
        setRequested(true);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setSubmit(false);
      });
  };
  return requested ? (
    <SuccessPage />
  ) : (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values) => {
        handleForgotPassword(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Page>
          <div className="login-page">
            <Grid.Row>
              <div className="col-lg-6 form-wrapper">
                <Grid.Row>
                  <div className="col-md-6 col-lg-8 form-body">
                    <label className="welcome-title create-password">
                      Forgot Password?
                    </label>
                    <label className="label-title">
                      You will shortly recieve an email with a password recovery
                      link.
                    </label>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="form-group">
                        <Field
                          name="email"
                          className={
                            errors.email && touched.email
                              ? "form-control is-invalid state-invalid"
                              : "form-control"
                          }
                          placeholder="Email Address"
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </div>
                      {alert.message && (
                        <Alert type={alert.type}>{alert.message}</Alert>
                      )}
                      <button
                        type="submit"
                        className="btn btn-block btn-barlows"
                        disabled={isLoading}
                      >
                        Reset Password
                      </button>
                    </form>
                    <span className="flex">
                      <label className="label-title">
                        Remember it already? &nbsp;
                      </label>
                      <NavLink className="nav-link" to="/login">
                        Log In
                      </NavLink>
                    </span>
                  </div>
                </Grid.Row>
              </div>
              <div className="col-lg-6 ">
                <div className="ev-logo password-logo"></div>
              </div>
            </Grid.Row>
            <PageFooter />
          </div>
        </Page>
      )}
    />
  );
}

export default withRouter(ForgotPasswordPage);
