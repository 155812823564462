import axios from "axios";
import {BASE_URL} from './type'

export function getUptimeReports(params, stationId) {
  let url = "uptime-reports";
  if (stationId) {
    url += `/${stationId}`;
  }
  return axios.get(BASE_URL + url, { params });
}
