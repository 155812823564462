import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import * as StripeTransactionService from "../../store/services/stripeTransactionsService";
import StripePaymentNav from "./StripePaymentNav";
import * as billingServices from "../../store/services/billServices";
import SiteWrapper from "../../SiteWrapper";
import Svgicon from "../../components/SvgIcon";

const formSchema = Yup.object().shape({
    sortCode: Yup.string().required("Details don't match. Please check they are correct and try again."),
    confirmSortCode: Yup.string().required("Details don't match. Please check they are correct and try again."),
    accountNumber: Yup.string().required("Details don't match. Please check they are correct and try again."),
    confirmAccountNumber: Yup.string().required("Details don't match. Please check they are correct and try again."),
});

function PaymentSettings(props) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        sortCode: '',
        confirmSortCode: '',
        accountNumber: '',
        confirmAccountNumber: ''
    });
    const [alert, setAlert] = useState({});

    const handleSubmit = (values) => {
        if (values.sortCode !== values.confirmSortCode || values.accountNumber !== values.confirmAccountNumber) {
            setAlert({
                type: "danger",
                message: "Details don't match. Please check they are correct and try again.",
            });
            return;
        }
        setLoading(true);
        billingServices.addPayout(values)
            .then((response) => {
                setLoading(false);
                // props.history.push("/payments/transactions");
                setAlert({
                    type: "success",
                    message: 'Payout information saved',
                });
            })
            .catch((error) => {
                setAlert({
                    type: "danger",
                    message: (error.response && error.response.data.message) || error.message,
                });
                setLoading(false);
            });
    }
    const handleOnboarding = () => {
        setLoading(true);
        StripeTransactionService.getOnboarding()
            .then((response) => {
                setLoading(false);
                window.location = response.data.account.url;
            })
            .catch((error) => { });
    };
    useEffect(() => {

    }, [])
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                handleSubmit(values);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

            }) => (
                <SiteWrapper loading={loading}>
                    <StripePaymentNav />
                    <Page.Content>
                        {props.match.params.param && (
                            <Alert type={'success'}>
                                Thanks for providing the legal information for your payment processing account. Please add your payout information below to finish the setup.
                            </Alert>
                        )}
                        <Page.Header title="Payment Settings" />
                        <Grid.Row cards={true}>
                            <Grid.Col width={12}>
                                <Card className="charge-point-card">
                                    <Grid.Row>
                                        <Grid.Col width={12} lg={8} className="m-auto">
                                            <Card.Header className="d-flex justify-content-between">
                                                <Svgicon name='payments' />
                                                <Card.Title className='new-payment-title'>
                                                    Payout Settings
                                                </Card.Title>
                                                <div className="ml-auto lh-1">
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => props.history.push("/payments/transactions")}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Card.Header>
                                        </Grid.Col>
                                        <Grid.Col width={12} lg={12}>
                                            <div className="border-bottom"></div>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col width={12} lg={8} className="m-auto">
                                            <Card.Body className='pt-40'>
                                                <div className='pb-40' style={{ color: '#74797E' }}>
                                                    Enter the sort code and account of where you would like any payments from public chargers paid out to.
                                                </div>
                                                <form
                                                    onSubmit={handleSubmit}
                                                    autoComplete="off"
                                                >
                                                    <Grid.Row>
                                                        <Grid.Col width={12} md={6}>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="sortCode"
                                                                    className={
                                                                        errors.sortCode && touched.sortCode
                                                                            ? "form-control is-invalid state-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    placeholder="Sort Code"
                                                                />

                                                            </div>
                                                        </Grid.Col>
                                                        <Grid.Col width={12} md={6}>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="confirmSortCode"
                                                                    className={
                                                                        errors.confirmSortCode && touched.confirmSortCode
                                                                            ? "form-control is-invalid state-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    placeholder="Cofirm Sort Code"
                                                                />

                                                            </div>
                                                        </Grid.Col>
                                                        <Grid.Col width={12} md={6}>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="accountNumber"
                                                                    className={
                                                                        errors.accountNumber && touched.accountNumber
                                                                            ? "form-control is-invalid state-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    placeholder="Account Number"
                                                                />
                                                                {(
                                                                    <div className="invalid-feedback">
                                                                        {errors.accountNumber || errors.sortCode || errors.confirmSortCode || errors.confirmAccountNumber}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Grid.Col>
                                                        <Grid.Col width={12} md={6}>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="confirmAccountNumber"
                                                                    className={
                                                                        errors.confirmAccountNumber && touched.confirmAccountNumber
                                                                            ? "form-control is-invalid state-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    placeholder="Cofirm Account Number"
                                                                />

                                                            </div>
                                                        </Grid.Col>

                                                    </Grid.Row>

                                                    {alert.message && (
                                                        <Alert type={alert.type}>{alert.message}</Alert>
                                                    )}
                                                    <button
                                                        type="submit"
                                                        className="btn btn-barlows mt-40 mb-4"
                                                        disabled={loading}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </form>
                                            </Card.Body>
                                        </Grid.Col>
                                    </Grid.Row>


                                    <Grid.Row>
                                        <Grid.Col width={12} lg={12}>
                                            <div className='border-bottom'></div>
                                        </Grid.Col>
                                        <Grid.Col width={12} lg={8} className='m-auto'>
                                            <Card.Header className='d-flex justify-content-between'>
                                                <Card.Title>Update Legal Information</Card.Title>
                                            </Card.Header>
                                        </Grid.Col>
                                        <Grid.Col width={12} lg={12}>
                                            <div className='border-bottom'></div>
                                        </Grid.Col>
                                        <Grid.Col width={12} lg={8} className='m-auto'>
                                            <Card.Body className='pt-40 pb-40'>
                                                <div className='pb-40' style={{ color: '#74797E' }}>
                                                    If you need to update your business information, owners and directors then please click the button below.
                                                </div>

                                                <div className=''>
                                                    <Button onClick={handleOnboarding} color="secondary" type='button'>
                                                        Update Legal Information
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                    </Page.Content>
                </SiteWrapper>
            )}
        />
    );
}

export default PaymentSettings;