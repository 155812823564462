import axios from "axios";
import {BASE_URL} from './type';
export function getTransations(params) {
  return axios.get(BASE_URL + "transactions-user", {params});
}
export function getAllTransactions() {
  return axios.get(BASE_URL + "transactions-all");
}
export function getEnergy(params) {
  return axios.get(BASE_URL + "transactions-energy", {params});
}
export function getChargeTime(params) {
  return axios.get(BASE_URL + "transactions-chargetime", {params});
}
export function getRevenue(params) {
  return axios.get(BASE_URL + "transactions-revenue", {params});
}
