// @flow

import * as React from "react";
import { useState, useEffect, useFocus, useRef } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import SiteWrapper from "../SiteWrapper";
import * as userService from "../store/services/userService";
import * as siteService from "../store/services/siteService";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";
const formSchema = Yup.object().shape({
  name: Yup.string().required("Site Name is required"),
  street: Yup.string().required("Address Line 1 is required"),
  city: Yup.string().required("Region is required"),
  postcode: Yup.string().required("Postcode is required"),
});

function SitesPage(props) {
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [users, setUsers] = React.useState([]);
  const [filterParams, setParams] = React.useState({
    page: 1,
    "filter[type]": "",
  });
  const handleChangeRoleFilter = (values) => {
    setParams({
      ...filterParams,
      ...{ "filter[type]": values.currentTarget.value },
    });
  };
  React.useEffect(() => {
    setLoading(true);
    userService
      .getUsers(filterParams)
      .then((response) => {
        setUsers(response.data.users.data);
        setTotalPage(Math.ceil(response.data.users.total / 10));
        setCurrentPage(response.data.users.current_page);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [filterParams]);

  return (
    <SiteWrapper loading={loading}>
      <Page.Content>
        <div className="page-header">
          <h1 className="page-title">Users</h1>
          <div className="page-options d-flex">
            <Button
              color="barlows"
              onClick={() => {
                props.history.push("users/new");
              }}
            >
              Add New <i className="fe fe-plus mr-2"></i>
            </Button>
          </div>
        </div>
        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Card.Title>Users</Card.Title>
                <Card.Options>
                  <Form.Select
                    className="form-select w-auto mr-2"
                    onChange={handleChangeRoleFilter}
                  >
                    <option value="">Filter by Role</option>
                    <option value="Admin">Admin</option>
                    <option value="Manager">Manager</option>
                    <option value="Customer">Customer</option>
                  </Form.Select>
                </Card.Options>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table card-table table-vcenter datatable"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>NAME</Table.ColHeader>
                    <Table.ColHeader>ROLE</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {users.map((user, key) => (
                    <Table.Row key={key}>
                      <Table.Col className="fit-content">{user.name}</Table.Col>
                      <Table.Col>{user.type}</Table.Col>
                      <Table.Col>
                        <Button
                          color="secondary"
                          onClick={() => {
                            props.history.push("/users/" + user.id);
                          }}
                        >
                          Edit
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div className="pagination-barlows">
                <Button
                  color="secondary"
                  onClick={() => {
                    setParams({
                      ...filterParams,
                      ...{ page: currentPage - 1 },
                    });
                  }}
                  disabled={currentPage === 1}
                >
                  Prev
                </Button>

                <span>
                  {currentPage} of {totalPage}
                </span>
                <Button
                  color="secondary"
                  onClick={() => {
                    setParams({
                      ...filterParams,
                      ...{ page: currentPage + 1 },
                    });
                  }}
                  disabled={currentPage === totalPage}
                >
                  Next
                </Button>
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

function AddNewSite(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [initialValues, setInitialValues] = useState({
    name: "",
    street: "",
    street2: "",
    city: "",
    postcode: "",
  });
  const handleSubmit = (values) => {
    setLoading(true);
    if (values.id) {
      siteService
        .updateSite(values.id, values)
        .then((response) => {
          setLoading(false);
          setAlert({
            type: "success",
            message: "Successfully updated changed data",
          });
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
          setLoading(false);
        });
    } else {
      let data = Object.assign(values);
      data["company_id"] = props.match.params.companyId;
      siteService
        .createSite(data)
        .then((response) => {
          setLoading(false);
          history.push("/companies/" + props.match.params.companyId);
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      siteService
        .getSiteById(props.match.params.id)
        .then((response) => {
          let values = response.data.site;
          setInitialValues({
            id: values.id,
            name: values.name,
            street: values.street,
            street2: values.street2 || "",
            city: values.city,
            postcode: values.postcode,
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
        });
    }
  }, []);
  const deleteSite = () => {
    setLoading(true);
    siteService
      .deleteSite(initialValues.id)
      .then((response) => {
        setLoading(false);
        props.history.push("/companies/" + props.match.params.companyId);
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message:
            (error.response && error.response.data.message) || error.message,
        });
      });
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        handleSubmit(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <SiteWrapper loading={loading}>
          <Page.Content>
            <Page.Header title="Sites" />
            <Grid.Row cards={true}>
              <Grid.Col width={12}>
                <Card className="charge-point-card">
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className="m-auto">
                      <Card.Header className="d-flex justify-content-between">
                        <Card.Title>
                          {props.match.params.id ? "Edit " : "Add New "}Site
                        </Card.Title>
                        <div className="ml-auto lh-1">
                          <Button
                            color="secondary"
                            onClick={() => {
                              props.match.params.id
                                ? props.history.push(
                                  `/companies/${props.match.params.companyId}/sites/${props.match.params.id}`
                                )
                                : props.history.push(
                                  "/companies/" + props.match.params.companyId
                                );
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Card.Header>
                    </Grid.Col>
                    <Grid.Col width={12} lg={12}>
                      <div className="border-bottom"></div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className="m-auto">
                      <Card.Body>
                        <form
                          onSubmit={handleSubmit}
                          autoComplete="off"
                          className="mt-5"
                        >
                          <Grid.Row>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="name"
                                  className={
                                    errors.name && touched.name
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Site Name"
                                />
                                {errors.name && touched.name && (
                                  <div className="invalid-feedback">
                                    {errors.name}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="street"
                                  className={
                                    errors.street && touched.street
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Address Line 1"
                                />
                                {errors.street && touched.street && (
                                  <div className="invalid-feedback">
                                    {errors.street}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="street2"
                                  className={
                                    errors.street && touched.street
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Address Line 2"
                                />
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="city"
                                  className={
                                    errors.city && touched.city
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Region"
                                />
                                {errors.city && touched.city && (
                                  <div className="invalid-feedback">
                                    {errors.city}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="postcode"
                                  className={
                                    errors.postcode && touched.postcode
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Postcode"
                                />
                                {errors.postcode && touched.postcode && (
                                  <div className="invalid-feedback">
                                    {errors.postcode}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                          </Grid.Row>

                          {alert.message && (
                            <Alert type={alert.type}>{alert.message}</Alert>
                          )}
                          <button
                            type="submit"
                            className="btn btn-barlows mt-5 mb-4"
                            disabled={loading}
                          >
                            {props.match.params.id
                              ? "Save Changes"
                              : "Add New Site"}
                          </button>
                        </form>
                      </Card.Body>
                    </Grid.Col>
                  </Grid.Row>
                  {props.match.params.id && (
                    <Grid.Row className="mt-9">
                      <Grid.Col width={12} lg={8} className="m-auto ">
                        <Card.Body>
                          <div className="d-flex  user-page mt-8">
                            <Button outline color="danger" onClick={handleShow}>
                              Delete Site
                            </Button>
                            <span
                              style={{
                                color: "#FF4747",
                                fontSize: 11,
                                marginLeft: 20,
                              }}
                            >
                              Note: The charge points under this site will also
                              be deleted.
                            </span>
                          </div>
                        </Card.Body>
                        <Modal
                          show={show}
                          centered={true}
                          onHide={handleClose}
                          dialogClassName="delete-user"
                        >
                          <Modal.Body>
                            <div
                              style={{ textAlign: "right", cursor: "pointer" }}
                            >
                              <svg
                                width="21px"
                                height="20px"
                                viewBox="0 0 32 32"
                                onClick={handleClose}
                              >
                                <g
                                  stroke="#E5E9EF"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    transform="translate(-419.000000, -240.000000)"
                                    fill="#E5E9EF"
                                  >
                                    <g
                                      id="icons"
                                      transform="translate(56.000000, 160.000000)"
                                    >
                                      <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="d-flex justify-content-center align-items-center notification-content">
                              <svg
                                width="50px"
                                height="50px"
                                viewBox="0 0 50 50"
                              >
                                <title>
                                  F43EE6C7-25E5-4915-8583-45C7C5200063
                                </title>
                                <g
                                  id="Desktop-App---BRANDED-V2"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Settings---Delete-User-Modal"
                                    transform="translate(-695.000000, -376.000000)"
                                    fill="#FF4747"
                                  >
                                    <g
                                      id="Group-8"
                                      transform="translate(530.000000, 336.000000)"
                                    >
                                      <g
                                        id="Group-6"
                                        transform="translate(165.000000, 40.000000)"
                                      >
                                        <rect
                                          id="Rectangle"
                                          fill-opacity="0.1"
                                          x="0"
                                          y="0"
                                          width="50"
                                          height="50"
                                          rx="5"
                                        ></rect>
                                        <g
                                          id="delete"
                                          transform="translate(15.000000, 15.000000)"
                                        >
                                          <path
                                            d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z"
                                            id="Shape"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              <div className="content-title">Delete Site?</div>
                              <div className="content-note">
                                Are you sure you want to delete this site?
                              </div>
                              <div
                                className="d-flex user-page"
                                style={{ marginTop: 20 }}
                              >
                                <Button
                                  outline
                                  color="danger"
                                  onClick={deleteSite}
                                >
                                  Yes, Delete Site
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </Grid.Col>
                    </Grid.Row>
                  )}
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </SiteWrapper>
      )}
    />
  );
}

function SingleSite(props) {
  const [filterParams, setParams] = useState({
    page: 1,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [alert, setAlert] = useState({});
  const [siteData, setSiteData] = useState({
    name: "",
    company: {},
    transaction_count: 0,
    station_count: 0,
  });
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const options = (
    <React.Fragment>
      <Button
        color="secondary"
        onClick={() => {
          props.history.push(`/companies/${props.match.params.companyId}`);
        }}
      >
        Back
      </Button>
    </React.Fragment>
  );
  const convertHours = (value) => {
    var n = new Date(0, 0);
    // n.setSeconds(+value * 60 * 60);
    n.setSeconds(+value);
    return n.toTimeString().slice(0, 5);
  };
  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      siteService
        .getSiteById(props.match.params.id, filterParams)
        .then((response) => {
          setSiteData(response.data.site);
          setTotalPage(Math.ceil(response.data.stations.total / 10));
          setCurrentPage(response.data.stations.current_page);
          setStations(response.data.stations.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
        });
    }
  }, [filterParams]);
  return (
    <SiteWrapper loading={loading}>
      <Page.Content>
        <Page.Header title="Sites" options={options} />
        <Grid.Row cards={true}>
          <Grid.Col width={12} lg={6}>
            <Card className=" h-100">
              <Card.Header className="d-flex justify-content-between">
                <Card.Title>Details</Card.Title>
              </Card.Header>
              <Card.Body>
                <Grid.Row className="mb-5">
                  <Grid.Col>
                    <div className="subheader mb-3">SITE NAME</div>
                    <div className="details">{siteData.name}</div>
                  </Grid.Col>
                  <Grid.Col>
                    <div className="subheader mb-3">COMPANY NAME</div>
                    <div className="details">{siteData.company.name}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="mb-5">
                  <Grid.Col>
                    <div className="subheader mb-3">LOCATION</div>
                    <div className="details">
                      {siteData.company.street ? (siteData.company.street + " " +
                        (siteData.company.street2 ? siteData.company.street2 : "") +
                        " " + siteData.company.city + " " + siteData.company.postcode) : ''}
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    {alert.message && (
                      <Alert type={alert.type}>{alert.message}</Alert>
                    )}
                  </Grid.Col>

                  <Grid.Col>
                    <Button
                      color="secondary"
                      onClick={() => {
                        props.history.push(
                          `/companies/${props.match.params.companyId}/sites/edit/${props.match.params.id}`
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col width={12} lg={6} className="details-card">
            <Grid.Row>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center">
                    <div className="h1 m-3">{siteData.transaction_count}</div>
                    <div className="mb-3">Transactions</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center">
                    <div className="h1 m-3">{siteData.station_count}</div>
                    <div className="mb-3">Charge Points</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center">
                    <div className="h1 m-3">
                      £{siteData.revenue && (siteData.revenue / 100).toFixed(2)}
                    </div>
                    <div className="mb-3">Revenue</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center padding-sm-0">
                    <div className="h1 m-3">
                      {convertHours(siteData.total_charge_time)}
                    </div>
                    <div className="mb-3">Total charge time (hrs)</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card className={stations.length === 0 ? 'user-fixed-card' : ''}>
              <Card.Header>
                <Card.Title>Charge Point Summary</Card.Title>
                <Card.Options>
                  <Button
                    color="barlows"
                    onClick={() => {
                      props.history.push("/charge-points/new");
                    }}
                  >
                    Add New <i className="fe fe-plus mr-2"></i>
                  </Button>
                </Card.Options>
              </Card.Header>
              {stations.length > 0 ?
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table card-table table-vcenter datatable"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>LOCATION</Table.ColHeader>
                      <Table.ColHeader>UPTIME</Table.ColHeader>
                      <Table.ColHeader>DOWNTIME</Table.ColHeader>
                      <Table.ColHeader />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {stations.map((station, i) => (
                      <Table.Row key={i}>
                        <Table.Col>{station.id}</Table.Col>
                        <Table.Col className="text-nowrap mobile-locations">
                          {station.street}
                        </Table.Col>
                        <Table.Col>
                          {station.total_count
                            ? Math.floor(
                              (station.up_count / station.total_count) * 100
                            ).toFixed(0)
                            : 0}
                          %
                        </Table.Col>
                        <Table.Col>
                          {station.total_count
                            ? (
                              100 -
                              Math.floor(
                                (station.up_count / station.total_count) * 100
                              )
                            ).toFixed(0)
                            : 100}
                          %
                        </Table.Col>
                        <Table.Col>
                          <Button
                            color="secondary"
                            onClick={() => {
                              props.history.push("/charge-points/" + station.id);
                            }}
                          >
                            View
                          </Button>
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table> :
                <Card.Body className='align-items-center justify-content-center'>
                  <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                    No charge points found
                  </h4>
                  <div className="detail mb-5">You haven't added any charge points to this site</div>
                </Card.Body>
              }
              {stations.length > 0 && (
                <div className="pagination-barlows">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage - 1 },
                      });
                    }}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </Button>

                  <span>
                    {currentPage} of {totalPage}
                  </span>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage + 1 },
                      });
                    }}
                    disabled={currentPage === totalPage}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}
export default SitesPage;
export { AddNewSite, SingleSite };
