import React from 'react';
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import { NavDropdown } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Increase, Decrease } from './increase'

function ApexChart({ title, unit, period, changePeriod, data }) {

    const { categories, series, value, type, percent } = data;

    let chartOptions = {
        options: {
            chart: {
                id: "area",
                toolbar: {
                    show: false
                },
                width: '100%',
                // height: '100%',
                offsetX: 0,
                // offsetY: 23,
                parentHeightOffset: 0,
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#000000'],
            stroke: {
                width: 2,
                curve: 'smooth'
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                labels: {
                    show: false
                },
                categories: categories,
                tooltip: {
                    enabled: false,
                }
            },
            grid: {
                show: false
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                labels: {
                    show: false
                },

            },
            legend: {
                show: false
            },
            
        },
        series: [
            {
                name: "Value",
                data: series,
            },
        ],
    }
    const getUnit = () => {
        if (type === 1) {
            return 'kwh'
        } else if (type === 2) {
            return 'hrs'
        } else return null
    }
    return (
        <Grid.Col width={12} lg={4}>
            <div className="card h-100" style={{ paddingHorizontal: 0 }}>
                <div className="card-body" style={{ paddingBottom: 0 }}>
                    <div className="d-flex align-items-center">
                        <div className="subheader">{title}</div>
                        <div className="ml-auto lh-1">
                            <NavDropdown alignRight muted title={period}>
                                <NavDropdown.Item
                                    onClick={() => changePeriod('Last 7 days', type)}
                                >
                                    Last 7 days
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => changePeriod('Last 30 days', type)}
                                >
                                    Last 30 days
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => changePeriod('Last 3 months', type)}
                                >
                                    Last 3 months
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </div>
                    <div className="d-flex  mt-2 align-items-baseline">
                        <div className="h1 mb-3">
                            {value}
                            <span style={{fontSize: 13, fontWeight: '300'}}>{getUnit()}</span></div>
                        <div className="ml-2">
                            {percent === 0 ?
                                null :
                                <span style={{ color: percent > 0 ? '#000000' : '#FF4747' }} >
                                    {(Math.abs(percent)).toFixed(2)}% {' '}
                                    {percent < 0 ? <Decrease /> :
                                        <Increase />}
                                </span>}
                        </div>
                    </div>
                    <div style={{ borderRadius: 12, height: '74%', margin: '-44px -30px -21px -42px' }}>
                        <ReactApexChart
                            options={chartOptions.options}
                            series={chartOptions.series}
                            type="area"
                            height={'100%'}
                            width='100%'
                        ></ReactApexChart>
                    </div>
                </div>
            </div>
        </Grid.Col>
    )
}

export default ApexChart;