import React from 'react'

export const Increase = () => (
    <svg width="21px" height="14px" viewBox="0 0 21 14" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
            <g transform="translate(-640.000000, -207.000000)" stroke="#000000" strokeWidth="1.5">
                <g transform="translate(530.000000, 148.000000)">
                    <g transform="translate(88.000000, 55.000000)">
                        <g transform="translate(24.000000, 5.000000)">
                            <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                            <polyline transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) " points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const Decrease = () => (
    <svg
        width="21px"
        height="14px"
        viewBox="0 0 21 14"
        version="1.1"
    >
        <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="square"
        >
            <g
                transform="translate(-1018.000000, -282.000000)"
                stroke="#FF4747"
                strokeWidth="1.5"
            >
                <g transform="translate(930.000000, 222.000000)">
                    <g transform="translate(66.000000, 55.000000)">
                        <g transform="translate(32.500000, 12.000000) scale(1, -1) translate(-32.500000, -12.000000) translate(24.000000, 6.000000)">
                            <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                            <polyline
                                transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                            ></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
