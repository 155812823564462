import axios from "axios";
import { BASE_URL } from "./type";
export function getDisputes(params) {
  return axios.get(BASE_URL + "disputes", { params });
}
export function getDisputesById(id) {
  return axios.get(BASE_URL + "disputes/" + id);
}
export function getDisputesDashboard() {
  return axios.get(BASE_URL + "disputes/dashboard");
}
export function updateDisputes(id, data) {
  return axios.post(BASE_URL + "disputes/" + id, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function closeDisputes(id) {
  return axios.delete(BASE_URL + "disputes/" + id);
}
