import axios from "axios";
import { BASE_URL } from "./type";

export function getOnboarding() {
  return axios.get(BASE_URL + "stripe-onboarding");
}

export function checkStripeAccount() {
  return axios.get(BASE_URL + "stripe-checkaccount");
}

export function getTransations(params) {
  return axios.get(BASE_URL + "stripe-transactions", { params });
}
export function getNextPayouts() {
  return axios.get(BASE_URL + "stripe-next-payouts");
}

export function getTotalTransations(params) {
  return axios.get(BASE_URL + "stripe-total-transactions", { params });
}

export function getTransactionById(id) {
  return axios.get(BASE_URL + "stripe-transactions/" + id);
}

export function createRefund(data) {
  return axios.post(BASE_URL + "stripe-refund", data);
}
