// @flow

import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Page, Grid, Form, Alert } from "tabler-react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PageFooter from "./PageFooter";
import * as authService from "../store/services/authServices";
import { authLogin } from "../store/actions/authActions";
import Loading from "react-loading";
import { useHistory } from "react-router-dom";

const LoginSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "Password must match"),
});
const SuccessPage = () => {
  const history = useHistory();
  const goToLogin = () => {
    history.push("/login");
  };
  return (
    <Page>
      <div className='login-page'>
        <Grid.Row>
          <div className='col-lg-6'>
            <Grid.Row>
              <div className='col-md-6 col-lg-8 form-body' style={{ marginTop: "auto" }}>
                <label className='welcome-title'>Thanks for registering</label>
                <label className='label-title'> We need to verify your email address before you can login. Check your inbox for an email from us which has instructions on what to do next.</label>
                {/* <label className='label-title'>Your account has been registered successfully!</label> */}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                  autoComplete='off'
                >
                  <button type='submit' className='btn btn-block btn-barlows'>
                    Back to Log In
                  </button>
                </form>
              </div>
            </Grid.Row>
          </div>
          <div className='col-lg-6 '>
            <div className='ev-logo password-logo'></div>
          </div>
        </Grid.Row>
        <PageFooter />
      </div>
    </Page>
  );
};
function RegisterPage(props) {
  const [isLoading, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const handleRegister = (data) => {
    setSubmit(true);
    authService
      .register(data)
      .then((res) => {
        setSubmit(false);
        setSuccess(true);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setSubmit(false);
      });
  };
  return isSuccess ? (
    <SuccessPage />
  ) : (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        type: "Admin",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting, setErrors /* setValues and other goodies */ }) => {
        if (values.name.split(" ").length > 1) {
          values.type = "Admin";
          values.cardname = "";
          handleRegister(values);
        } else {
          setErrors({ name: "Please enter full name." });
        }
      }}
      render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div className='page'>
          {isLoading && (
            <div className='loading'>
              <Loading type='spinningBubbles' color='#171717' />
            </div>
          )}
          <Page>
            <div className='login-page'>
              <Grid.Row>
                <div className='col-lg-6 form-wrapper'>
                  <Grid.Row>
                    <div className='col-md-6 col-lg-8 form-body'>
                      <label className='welcome-title'>Create Account</label>
                      <label className='label-title'>Register by filling out the details below.</label>
                      <form onSubmit={handleSubmit} autoComplete='off'>
                        <div className='form-group'>
                          <Field
                            name='name'
                            className={errors.name && touched.name ? "form-control is-invalid state-invalid" : "form-control"}
                            placeholder='Name'
                          />
                          {errors.name && touched.name && <div className='invalid-feedback'>{errors.name}</div>}
                        </div>
                        <div className='form-group'>
                          <Field
                            name='email'
                            className={errors.email && touched.email ? "form-control is-invalid state-invalid" : "form-control"}
                            placeholder='Email Address'
                          />
                          {errors.email && touched.email && <div className='invalid-feedback'>{errors.email}</div>}
                        </div>
                        <div className='form-group'>
                          <Field
                            type='password'
                            name='password'
                            className={errors.password && touched.password ? "form-control is-invalid state-invalid" : "form-control"}
                            placeholder='Password'
                          />
                          {errors.password && touched.password && <div className='invalid-feedback'>{errors.password}</div>}
                        </div>
                        <div className='form-group'>
                          <Field
                            type='password'
                            name='password_confirmation'
                            className={
                              errors.password_confirmation && touched.password_confirmation ? "form-control is-invalid state-invalid" : "form-control"
                            }
                            placeholder='Repeat Password'
                          />
                          {errors.password_confirmation && touched.password_confirmation && (
                            <div className='invalid-feedback'>{errors.password_confirmation}</div>
                          )}
                        </div>
                        {alert.message && <Alert type={alert.type}>{alert.message}</Alert>}
                        <button type='submit' className='btn btn-block btn-barlows ' disabled={isLoading}>
                          Register
                        </button>
                      </form>
                      <span className='flex'>
                        <label className='label-title'>Already have account? &nbsp;</label>
                        <NavLink className='nav-link' to='/login'>
                          Log In
                        </NavLink>
                      </span>
                      {/* <NavLink className='nav-link' to='/forgot-password'>
                      Forgot Password?
                    </NavLink> */}
                    </div>
                  </Grid.Row>
                </div>
                <div className='col-lg-6 '>
                  <div className='ev-logo password-logo'></div>
                </div>
              </Grid.Row>
              <PageFooter />
            </div>
          </Page>
        </div>
      )}
    />
  );
}

export default RegisterPage;
