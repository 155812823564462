import { AUTH_LOGIN, AUTH_LOGOUT, SET_DETAILS_SUBMITTED } from "../types";

export const authLogin = (payload) => async (dispatch) => {
  dispatch({
    type: AUTH_LOGIN,
    payload: payload,
  });
};

export const authLogout = () => async (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
    payload: {},
  });
};

export const authRegister = (payload) => async (dispatch) => {
  dispatch({
    type: AUTH_LOGIN,
    payload: {},
  });
};

export const setDetailsSubmitted = (payload) => async (dispatch) => {
  dispatch({
    type: SET_DETAILS_SUBMITTED,
    payload: payload,
  });
};
