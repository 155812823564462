import axios from "axios";
import { BASE_URL, BACKENDURL } from "./type";

export function getUsers(params) {
  return axios.get(BASE_URL + "users", { params });
}

export function getUserById(id, params) {
  return axios.get(BASE_URL + "users/" + id, { params });
}
export function createUser(data) {
  return axios.post(BASE_URL + "users", data);
}

export function assignToUser(data) {
  return axios.post(BASE_URL + "assign-user", data);
}

export function unassignUser(data) {
  return axios.post(BASE_URL + "unassign-user", data);
}

export function updateUser(id, data) {
  return axios.put(BASE_URL + "users/" + id, data);
}

export function deleteUser(id) {
  return axios.delete(BASE_URL + "users/" + id);
}

export function setupAccount(path, data) {
  let url = new URL(path);
  let verifyUrl = url.pathname;
  if (process.env.NODE_ENV === "development") {
    verifyUrl = "http://localhost:8000" + verifyUrl;
  }

  if (process.env.NODE_ENV === "production") {
    verifyUrl = BACKENDURL.replace('/rest','') + verifyUrl;
  }
  return axios.post(verifyUrl, data);
}
export function verifyExpire(path) {
  let url = new URL(path);
  let verifyUrl = url.pathname + url.search;
  if (process.env.NODE_ENV === "development") {
    verifyUrl = "http://localhost:8000" + verifyUrl;
  }

  if (process.env.NODE_ENV === "production") {
    verifyUrl = BACKENDURL.replace('/rest','') + verifyUrl;
  }
  return axios.get(verifyUrl);
}

export function ResendInviteEmail(id) {
  return axios.get(BASE_URL + "users/resend-invite/" + id);
}

export function getReports(period) {
  return axios.get(BASE_URL + "users/reports", { params: { period: period } });
}

export function getOwnAccount() {
  return axios.get(BASE_URL + "user");
}
export function updateOwnAccount(data) {
  return axios.put(BASE_URL + "account", data);
}
