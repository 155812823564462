// @flow

import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Page, Grid, Form, Alert } from "tabler-react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PageFooter from "./PageFooter";
import * as authService from "../store/services/authServices";
import Loading from "react-loading";
import { authLogin } from "../store/actions/authActions";
const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

function LoginPage(props) {
  const [isLoading, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({});
  const [resendEmail, setResendEmail] = useState({});
  const [permission, setPermission] = useState({});
  const handleLogin = (data) => {
    setSubmit(true);
    authService
      .login(data)
      .then((res) => {
        setSubmit(false);
        localStorage.setItem("authToken", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        dispatch(authLogin(res.data));
        props.history.push("/");
      })
      .catch((err) => {
        if (err.response && err.response.data.status === 423) {
          setResendEmail({
            type: "resend",
            message: "Resend verification email?",
          });
        } else {
          setResendEmail({});
        }
        if(err.response && err.response.data.status === 403){
          setPermission({
            type: "permission",
            message: "click here",
          });
        }else {
          setPermission({});
        }
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setSubmit(false);
      });
  };
  const resendVerificationEmail = (values) => {
    authService
      .resendEmail(values)
      .then((res) => {
        setResendEmail({
          type: "sent",
          message: "Email Resent",
        });
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setResendEmail({});
      });
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting, setErrors /* setValues and other goodies */ }) => {
        handleLogin(values);
      }}
      render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div className='page'>
          {isLoading && (
            <div className='loading'>
              <Loading type='spinningBubbles' color='#171717' />
            </div>
          )}
          <Page>
            <div className='login-page'>
              <Grid.Row>
                <div className='col-lg-6 form-wrapper'>
                  <Grid.Row>
                    <div className='col-md-6 col-lg-8 form-body'>
                      <label className='welcome-title'>Welcome Back</label>
                      <label className='label-title'>Log in by filling out the details below.</label>
                      <form onSubmit={handleSubmit} autoComplete='off'>
                        <div className='form-group'>
                          <Field
                            name='email'
                            className={errors.email && touched.email ? "form-control is-invalid state-invalid" : "form-control"}
                            placeholder='Email Address'
                          />
                          {errors.email && touched.email && <div className='invalid-feedback'>{errors.email}</div>}
                        </div>
                        <div className='form-group'>
                          <Field
                            type='password'
                            name='password'
                            className={errors.password && touched.password ? "form-control is-invalid state-invalid" : "form-control"}
                            placeholder='Password'
                          />
                          {errors.password && touched.password && <div className='invalid-feedback'>{errors.password}</div>}
                        </div>

                        {alert.message && (
                          <Alert type={alert.type}>
                            {alert.message}&nbsp;
                            {resendEmail.type && (
                              <span
                                className={resendEmail.type == "resend" ? "text-decoration-underline cursor-pointer" : "text-decoration-underline "}
                                onClick={() => resendVerificationEmail(values)}
                              >
                                {resendEmail.message}
                              </span>
                            )}
                            {permission.type && (
                              <a
                               href="https://evaa.app/native-app/" style={{color: '#6b1110'}}
                                className={permission.type == "permission" ? "text-decoration-underline cursor-pointer" : "text-decoration-underline "}
                              >
                                {permission.message}
                              </a>
                            )}
                          </Alert>
                        )}
                        <button type='submit' className='btn btn-block btn-barlows ' disabled={isLoading}>
                          Log In
                        </button>
                      </form>
                      <NavLink className='nav-link' to='/forgot-password'>
                        Forgot Password?
                      </NavLink>
                      <span className='flex'>
                        <label className='label-title'>Don't have an account yet? &nbsp;</label>
                        <NavLink className='nav-link' to='/register'>
                          Register Now
                        </NavLink>
                      </span>
                    </div>
                  </Grid.Row>
                </div>
                <div className='col-lg-6 '>
                  <div className='ev-logo password-logo'></div>
                </div>
              </Grid.Row>
              <PageFooter />
            </div>
          </Page>
        </div>
      )}
    />
  );
}

export default LoginPage;
