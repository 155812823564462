import React, { useEffect, useState } from "react";
import { Page, Grid, Form, Alert, Error400Page } from "tabler-react";
import { NavLink, withRouter } from "react-router-dom";
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
import * as userService from "../store/services/userService";
import * as Yup from "yup";
import PageFooter from "./PageFooter";
const CreatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
});
const SuccessPage = ({ title, description }) => {
  const history = useHistory();
  const goToLogin = () => {
    history.push("/login");
  };
  return (
    <Page>
      <div className="login-page">
        <Grid.Row>
          <div className="col-lg-6">
            <Grid.Row>
              <div
                className="col-md-6 col-lg-8 form-body"
                style={{ marginTop: "auto" }}
              >
                <label className="welcome-title create-password">
                  {title ? title : "Ready to Go"}
                </label>
                <label className="label-title">
                  {description
                    ? description
                    : "Your account has been successfully verified. You can now Log In."}
                </label>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                  autoComplete="off"
                >
                  <button type="submit" className="btn btn-block btn-barlows">
                    Back to Log In
                  </button>
                </form>
              </div>
            </Grid.Row>
          </div>
          <div className="col-lg-6 ">
            <div className="ev-logo verified-logo"></div>
          </div>
        </Grid.Row>
        <PageFooter />
      </div>
    </Page>
  );
};
function SetPasswordPage(props) {
  const [isLoading, setSubmit] = useState(false);
  const [alert, setAlert] = useState({});
  const handleCreatePassword = (data) => {
    setSubmit(true);
    userService
      .setupAccount(props.path, data)
      .then((res) => {
        setSubmit(false);
        props.setPassword(true);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setSubmit(false);
      });
  };
  return (
    <Formik
      initialValues={{
        password: "",
        password_confirmation: "",
      }}
      validationSchema={CreatePasswordSchema}
      onSubmit={(values) => {
        handleCreatePassword(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Page>
          <div className="login-page">
            <Grid.Row>
              <div className="col-lg-6">
                <Grid.Row>
                  <div className="col-md-6 col-lg-8 form-body">
                    <label className="welcome-title create-password">
                      Create New Password
                    </label>
                    <label className="label-title">
                      Create your password which will be used to log in to your
                      account.
                    </label>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="form-group">
                        <Field
                          name="password"
                          type="password"
                          className={
                            errors.password && touched.password
                              ? "form-control is-invalid state-invalid"
                              : "form-control"
                          }
                          placeholder="Password"
                        />
                        {errors.password && touched.password && (
                          <div className="invalid-feedback">
                            {errors.password}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Field
                          type="password"
                          name="password_confirmation"
                          className={
                            errors.password_confirmation &&
                              touched.password_confirmation
                              ? "form-control is-invalid state-invalid"
                              : "form-control"
                          }
                          placeholder="Confirm Password"
                        />
                        {errors.password_confirmation &&
                          touched.password_confirmation && (
                            <div className="invalid-feedback">
                              {errors.password_confirmation}
                            </div>
                          )}
                      </div>
                      {alert.message && (
                        <Alert type={alert.type}>{alert.message}</Alert>
                      )}
                      <button
                        type="submit"
                        className="btn btn-block btn-barlows"
                        disabled={isLoading}
                      >
                        Create Password
                      </button>
                    </form>
                  </div>
                </Grid.Row>
              </div>
              <div className="col-lg-6 ">
                <div className="ev-logo password-logo"></div>
              </div>
            </Grid.Row>
            <PageFooter />
          </div>
        </Page>
      )}
    />
  );
}
function InviteUserPage(props) {
  const params = new URLSearchParams(props.location.search);
  const path = params.get("queryURL");
  const [isLoading, setSubmit] = useState(true);
  const [isVerified, setRequested] = useState(false);
  const [createPassword, setPassword] = useState(false);
  useEffect(() => {
    if (path) {
      userService
        .verifyExpire(path)
        .then(() => {
          setSubmit(false);
          setRequested(true);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setRequested(true);
            setPassword(true);
          }
          setSubmit(false);
        });
    } else {
    }
  }, []);
  return (
    !isLoading &&
    (isVerified ? (
      createPassword ? (
        <SuccessPage />
      ) : (
        <SetPasswordPage path={path} setPassword={setPassword} />
      )
    ) : (
      <SuccessPage
        title={"Expired"}
        description={
          "Check your inbox. We have sent you an email with instructions on how to reset you password."
        }
      />
    ))
  );
}

export default withRouter(InviteUserPage);
