import axios from "axios";
import { BASE_URL } from "./type";

export function addPaymentMothod(data) {
  return axios.post(BASE_URL + "add-payment", data);
}
export function addPayout(data) {
  return axios.post(BASE_URL + "add-payout", data);
}
export function updateSubscription(data) {
  return axios.put(BASE_URL + "update-subscription", data);
}
export function updatePaymentMethod(data) {
  return axios.put(BASE_URL + "update-payment", data);
}
export function getPaymentMethods() {
  return axios.get(BASE_URL + "payment-methods");
}
export function checkSubscription() {
  return axios.get(BASE_URL + "check-subscription");
}
export function cancelSub() {
  return axios.get(BASE_URL + "cancel-sub");
}
export function removeMethod(id) {
  return axios.delete(BASE_URL + "payment-methods/" + id);;
}