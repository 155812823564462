import axios from "axios";
import { BASE_URL } from './type'
export function getCompanies(params) {
  if (params) {
    return axios.get(BASE_URL + "companies-page", { params });
  }
  return axios.get(BASE_URL + "companies");
}
export function getCompanyById(id, params) {
  return axios.get(BASE_URL + "companies/" + id, { params })
}
export function updateCompany(id, params) {
  return axios.put(BASE_URL + "companies/" + id, params)
}
export function createCompany(data) {
  return axios.post(BASE_URL + "companies", data);
}
export function deleteCompany(id) {
  return axios.delete(BASE_URL + "companies/" + id);
}