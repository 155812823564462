import React from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import { ReactComponent as TransactionSVG } from "../../assets/images/single-Transactions.svg";
import { ReactComponent as DisputesSVG } from "../../assets/images/s-Disputes.svg";

const StripePaymentNav = () => {
  return (
    <div className='header payment-header'>
      <div className='container'>
        <Navbar>
          <Navbar.Collapse>
            <Nav className='mr-auto'>
              <NavLink className='nav-link' to='/payments/transactions'>
                <TransactionSVG />
                Transactions
              </NavLink>
              <NavLink className='nav-link' to='/payments/disputes'>
                <DisputesSVG />
                Disputes
              </NavLink>
              <NavLink className='nav-link' to='/payments/settings'>
                <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1'>
                  <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                    <g transform='translate(-821.000000, -17.000000)' stroke='currentColor' strokeWidth='2'>
                      <g transform='translate(822.000000, 18.000000)'>
                        <line x1='10' y1='20' x2='10' y2='0' id='Line'></line>
                        <line x1='20' y1='10' x2='0' y2='10' id='Line'></line>
                        <line x1='17.0710678' y1='2.92893219' x2='2.92893219' y2='17.0710678' id='Line'></line>
                        <line x1='2.92893219' y1='2.92893219' x2='17.0710678' y2='17.0710678' id='Line'></line>
                        <circle id='Oval' fill='#FFFFFF' cx='10' cy='10' r='6.5'></circle>
                      </g>
                    </g>
                  </g>
                </svg>
                Settings
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default StripePaymentNav;
