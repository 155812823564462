// @flow

import React, { useRef, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import ReactApexChart from "react-apexcharts";
import Socketio from "socket.io-client";
import * as billService from "../store/services/billServices";
import Svgicon from "../components/SvgIcon";
import axios from "axios";
import SiteWrapper from "../SiteWrapper";
import { FRONTENDURL } from "../store/services/type";
// import EchoInstance from "../store/Echo";
function SettingsPage(props) {
  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function addMessage(message) {
    setMessages([...messages, message]);
  }

  useEffect(() => {
    billService.checkSubscription()
      .then((res) => {
        if (res.data.sub === 'canceled' || res.data.sub === 'failed') {
          setShowAlert(res.data.sub);
        }
      })
      .catch((error) => {
      });
  }, [waitingToReconnect]);



  return (
    <SiteWrapper>
      <Page.Content>
        {
          showAlert ?
            showAlert === 'canceled' ?
              <Alert type='danger-chargebox' icon='x-circle'>
                Your subscription has been cancelled. Please go the billing screen and select a package to reactivate your subscription.
              </Alert> :
              <Alert type='danger-chargebox' icon='x-circle'>
                <span>
                  Your subscription is currently inactive. You will no longer be able to access the software from the end of the current billing cycle. <a href={`${FRONTENDURL}/billing/plan`}>Click here</a> to update your subscription and continue using Evaa.
                </span>
              </Alert> : null
        }
        <div className="page-header">
          <h1 className="page-title">Settings</h1>

        </div>
        <Grid.Row cards={true}>
          <Grid.Col width={12} lg={6}>
            <Card className='h-100'>
              <Card.Body className='p-0'>
                <div
                  style={{
                    height: "auto",
                    minHeight: 300,
                    width: "100%",
                    position: "relative",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => props.history.push('/users')}
                >
                  <div>
                    <Svgicon name='user' />
                    <p style={{ fontSize: 16, color: '#000', fontFamily: 'SF Pro Text', fontWeight: 500 }}>Users</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default SettingsPage;
