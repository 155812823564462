import React, { useEffect, useState } from "react";
import SiteWrapper from "../../SiteWrapper";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import { NavDropdown } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import Svgicon from "../../components/SvgIcon";
import * as StripeTransactionService from "../../store/services/stripeTransactionsService";
import Moment from "react-moment";
import StripePaymentNav from "./StripePaymentNav";
import { useSelector, useDispatch } from "react-redux";
import { setDetailsSubmitted } from "../../store/actions/authActions";
const PaymentsPage = ({ history, loading, setLoading }) => {
  const { detailsSubmitted } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isOnboarding, setOnboarding] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: "bar",
        fontFamily: "inherit",
        height: 40.0,
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },

      grid: {
        strokeDashArray: 4,
      },
      xaxis: {
        labels: {
          padding: 0,
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        labels: {
          padding: 4,
          formatter: function (value) {
            return value + "";
          },
        },
      },
      labels: [],
      colors: ["#000000"],
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: "Users",
        data: [],
      },
    ],
  });
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [filterParams, setParams] = useState({
    page: 1,
    prev: null,
    next: null,
  });
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [balance, setBalance] = useState(0);
  const [nextPayouts, setNextPayouts] = useState(0);
  const [usersParams, setUsersParams] = useState(7);
  const [alert, setAlert] = useState({});

  React.useEffect(() => {
    setLoading(true);
    StripeTransactionService.getTransations(filterParams)
      .then((response) => {
        setTransactions(response.data.transactions.data);
        setHasMore(response.data.transactions.has_more);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
      });
  }, [filterParams]);
  React.useEffect(() => {
    StripeTransactionService.getNextPayouts()
      .then((response) => {
        setBalance(response.data.balance);
        setNextPayouts(response.data.next_payouts);
      })
      .catch((error) => {
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
      });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    StripeTransactionService.getTotalTransations({ last_date: usersParams })
      .then((response) => {
        setTotalTransactions(response.data.total_transactions);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
      });
  }, [usersParams]);
  return (
    <Grid.Row className='billing-cards' cards={true}>
      <Grid.Col width={12} lg={4}>
        <div className='card h-100'>
          <div className='card-body'>
            <div className='d-flex align-items-center'>
              <div className='subheader'>BALANCE</div>
            </div>
            <div className='d-flex mb-5 mt-5 align-items-baseline'>
              <div className='h1 mb-3'>£{balance ? (balance / 100).toFixed(2) : "0.00"}</div>
            </div>
          </div>
        </div>
      </Grid.Col>
      <Grid.Col width={12} lg={4}>
        <div className='card h-100'>
          <div className='card-body'>
            <div className='d-flex align-items-center'>
              <div className='subheader'>IN TRANSIT TO BANK</div>
              {/* <div className='subheader'>NEXT PAYOUT</div> */}
            </div>
            <div className='d-flex mb-5 mt-5 align-items-baseline'>
              <div className='h1 mb-3'>£{nextPayouts ? (nextPayouts / 100).toFixed(2) : "0.00"}</div>
            </div>
          </div>
        </div>
      </Grid.Col>
      <Grid.Col width={12} lg={4}>
        <div className='card h-100'>
          <div className='card-body total-transactions'>
            <div className='d-flex align-items-center'>
              <div className='subheader'>TOTAL TRANSACTIONS</div>
              <div className='ml-auto lh-1 total-transaction-days'>
                <NavDropdown
                  alignRight
                  muted
                  title={
                    `Last ${usersParams > 30 ? usersParams / 30 + " months" : usersParams + " days"}`
                  }
                  className='dashboard-dropdown'
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setUsersParams(7);
                    }}
                  >
                    Last 7 days
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => {
                      setUsersParams(30);
                    }}
                  >
                    Last 30 days
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => {
                      setUsersParams(90);
                    }}
                  >
                    Last 3 months
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
            <div className='d-flex align-items-baseline total-transaction-value'>
              <div className='h1 mb-3'>£{totalTransactions ? (totalTransactions / 100).toFixed(2) : "0.00"}</div>
            </div>
          </div>
        </div>
      </Grid.Col>

      <Grid.Col lg={12}>
        <Card>
          <Card.Header>
            <Svgicon name='transaction' />
            <Card.Title>Transactions</Card.Title>
          </Card.Header>
          <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable'>
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>ID</Table.ColHeader>
                <Table.ColHeader>Date</Table.ColHeader>
                <Table.ColHeader>Type</Table.ColHeader>
                <Table.ColHeader>Charge Point ID</Table.ColHeader>
                {/* <Table.ColHeader>Customer Email</Table.ColHeader> */}
                <Table.ColHeader>Charge Id</Table.ColHeader>
                {/* <Table.ColHeader>Status</Table.ColHeader> */}
                <Table.ColHeader>Net Amount</Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {transactions.map(
                (item, i) =>
                  item.type !== "application_fee" &&
                  item.type !== "stripe_fx_fee" &&
                  item.type !== "stripe_fee" && (
                    <Table.Row key={i}>
                      <Table.Col>{item.id}</Table.Col>
                      <Table.Col className='text-nowrap'>
                        <Moment unix format='HH:mm DD MMM YYYY'>
                          {item.created}
                        </Moment>
                      </Table.Col>
                      <Table.Col className='text-capitalize'>{item.type}</Table.Col>
                      <Table.Col>{item.sation_id ? item.sation_id : ''}</Table.Col>
                      {/* <Table.Col></Table.Col> */}
                      <Table.Col>{item.source}</Table.Col>
                      {/* <Table.Col className='text-capitalize'>
                        <Moment unix format='DD MMM YYYY'>
                          {item.available_on}
                        </Moment>
                      </Table.Col> */}
                      <Table.Col className='price'>£{(item.net / 100).toFixed(2)}</Table.Col>
                      <Table.Col>
                        <Button
                          color='secondary'
                          onClick={() => {
                            history.push("/payments/transactions/" + item.id);
                          }}
                        >
                          View
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  )
              )}
            </Table.Body>
          </Table>
          {transactions.length === 0 && !loading && (
            <div className='get-payments'>
              <div className='title' style={{ color: '#000' }}>No Transactions</div>
              <div className='details' style={{ color: '#74797e' }}>You haven’t made any transactions yet.</div>
            </div>
          )}
          {transactions.length > 0 && (
            <div className='pagination-barlows'>
              <Button
                color='secondary'
                onClick={() => {
                  setCurrentPage(currentPage - 1);

                  setParams({
                    ...filterParams,
                    ...{ page: currentPage - 1, prev: transactions[0].id, next: null },
                  });
                }}
                disabled={currentPage === 1 || loading}
              >
                Prev
              </Button>

              <span>{currentPage}</span>
              <Button
                color='secondary'
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  setParams({
                    ...filterParams,
                    ...{ page: currentPage + 1, next: transactions[29].id, prev: null },
                  });
                }}
                disabled={(!hasMore && transactions.length !== 30) || loading}
              // disabled={currentPage === totalPage}
              >
                Next
              </Button>
            </div>
          )}
        </Card>
      </Grid.Col>
    </Grid.Row>
  );
};

export default PaymentsPage;
