import * as React from "react";

const PageFooter = () => {
  return (
    <div className="page-footer">
      <div className="footer-body">
        {/* <span>Copyright 2020</span> */}
        {/* <span>
          Design by{" "}
          <strong>
            <a href="https://kijo.co.uk/" target="_blank" style={{color: '#74797E'}}>
              KIJO
            </a>
          </strong>
        </span> */}
      </div>
    </div>
  );
};

export default PageFooter;
