import React, { useEffect, useRef, useState } from "react";
import { SOCKET_URL } from '../store/services/type'

const WebSocketClient = (props) => {
  let socket_url = SOCKET_URL;
  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);

  useEffect(() => {
    let timer;
    if (waitingToReconnect) {
      return;
    }
    if (!clientRef.current) {
      const client = new WebSocket(socket_url);
      clientRef.current = client;
      window.client = client;
      client.onerror = (e) => console.error(e);
      client.onopen = () => {
        // client.send("ping");
      };
      client.onclose = () => {
        if (clientRef.current) {
          props.setActiveStations([]);
        } else {
          return;
        }
        if (waitingToReconnect) {
          return;
        }
        setWaitingToReconnect(true);
        timer = setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      client.onmessage = (message) => {
        if (JSON.parse(message.data).stations) {
          props.setActiveStations(JSON.parse(message.data).stations);
        }
        if (JSON.parse(message.data).notifications && props.setNotifications) {
          props.setNotifications(JSON.parse(message.data).notifications);
        }
      };
    }
    return () => {
      if (clientRef.current) {
        clientRef.current.close();
      }
      clearTimeout(timer);
      clientRef.current = null;
      setWaitingToReconnect(true);
    };
  }, [waitingToReconnect]);

  const sendPing = () => {
    if (clientRef.current) {
      clientRef.current.send("ping");
    }
  };
  return <div></div>;
};

export default WebSocketClient;
