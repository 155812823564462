import * as React from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import { Alert } from "tabler-react";
import PageFooter from "./pages/PageFooter";
import { useDispatch } from "react-redux";
import * as authService from "./store/services/authServices";
import * as billService from "./store/services/billServices";
import { authLogout } from "./store/actions/authActions";
import Loading from "react-loading";
import { ReactComponent as PaymentSVG } from "./assets/images/Payments-nav.svg";
const SiteWrapper = (props, ...rest) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = React.useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});
  const [navExpanded, setNavExpanded] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(true);
  const containerRef = React.useRef(null);
  React.useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);
    window.addEventListener("storage", eventListenerFun);
    return () => window.removeEventListener("storage", eventListenerFun);
  }, []);
  const handleDocumentClick = (e) => {
    if (containerRef.current) {
      if (e.target !== containerRef && !containerRef.current.contains(e.target)) {
        setNavClose();
      }

    }
  };

  const eventListenerFun = (e) => {
    setUser(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")));
  };
  const handleSetNavExpanded = (expanded) => {
    setNavExpanded(expanded);
  };

  const setNavClose = () => {
    setNavExpanded(false);
  };
  const handleLogout = () => {
    authService
      .logout()
      .then(() => {
        dispatch(authLogout());
        history.push("/login");
        localStorage.clear();
      })
      .catch((error) => {
        dispatch(authLogout());
        history.push("/login");
        localStorage.clear();
      });
  };
  const firstName = () => {
    // if (user.name) {
    return user.name.split(" ")[0];
    // }
  };
  const lastName = () => {
    if (user.name) {
      return user.name.split(" ")[1];
    }
  };
  return (
    <div className='page'>
      {props.loading && (
        <div className='loading'>
          <Loading type='spinningBubbles' color='#171717' />
        </div>
      )}
      <div className='page-main'>
        <div className='header'>
          <div className='container'>
            <Navbar expand='md' onToggle={handleSetNavExpanded} expanded={navExpanded}>
              <Navbar.Toggle className='test' aria-controls='basic-navbar-nav' />
              <Navbar.Brand className='flex' href='/'>
                <img
                  src='/images/logo_icon.svg'
                  className='align-top app-logo1'
                  alt='EVAA'
                  style={{ width: 30, objectFit: "contain" }}
                />
                <div className='app-logo' />
              </Navbar.Brand>
              <Navbar.Collapse id='basic-navbar-nav' ref={containerRef}>
                <Nav className='mr-auto'>
                  <NavLink className='nav-link' exact to='/'>
                    <svg width='17px' height='19px' viewBox='0 0 17 19' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-290.000000, -19.000000)' stroke='currentColor' strokeWidth='2'>
                          <g transform='translate(290.000000, 19.000000)'>
                            <rect id='Rectangle' x='6' y='10.5' width='5' height='7'></rect>
                            <path
                              d='M8.79180255,1.22422273 L16,6.50692595 L16,18 L1,18 L1,6.52894387 L8.79180255,1.22422273 Z'
                              id='Rectangle-Copy'
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Dashboard
                  </NavLink>
                  <NavLink className='nav-link' to='/charge-points'>
                    <svg width='14px' height='21px' viewBox='0 0 14 21' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-431.000000, -17.000000)' stroke='currentColor' strokeWidth='2'>
                          <g transform='translate(431.000000, 16.000000)'>
                            <g id='Group' transform='translate(0.000000, 2.000000)'>
                              <rect id='Rectangle' x='1' y='2.5' width='12' height='16'></rect>
                              <line x1='5' y1='10.5' x2='9' y2='10.5' id='Line-Copy' strokeLinecap='round' strokeLinejoin='round'></line>
                              <line x1='5' y1='14.5' x2='9' y2='14.5' id='Line-Copy-3' strokeLinecap='round' strokeLinejoin='round'></line>
                              <line x1='5' y1='6.5' x2='9' y2='6.5' id='Line-Copy-2' strokeLinecap='round' strokeLinejoin='round'></line>
                              <line x1='5' y1='0.5' x2='9' y2='0.5' id='Line-Copy-4' strokeLinecap='round' strokeLinejoin='round'></line>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Charge Points
                  </NavLink>
                  <NavLink className='nav-link' to='/companies'>
                    <svg width='19px' height='18px' viewBox='0 0 19 18' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-576.000000, -19.000000)' stroke='currentColor' strokeWidth='2'>
                          <g transform='translate(576.000000, 19.000000)'>
                            <circle id='Oval' cx='9.5' cy='5' r='4'></circle>
                            <path
                              d='M9.5,11 C11.7237444,11 13.7409431,11.7939736 15.1995972,13.0905551 C16.36307,14.1247531 17.1697713,15.4812292 17.4187489,17 L17.4187489,17 L1.58125109,17 C1.83022868,15.4812292 2.63692999,14.1247531 3.80040281,13.0905551 C5.25905693,11.7939736 7.27625556,11 9.5,11 Z'
                              id='Oval-Copy'
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Companies
                  </NavLink>
                  <NavLink className='nav-link' exact to='/reports'>
                    <svg width='29px' height='20px' viewBox='0 0 29 20' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                        <g transform='translate(-705.000000, -18.000000)' stroke='currentColor' strokeWidth='2'>
                          <g>
                            <polyline points='706 28.963363 710.683016 28.963363 712.943679 24.1428571 718.278408 37 723.625041 19 726.280503 32.1130554 728.943899 28.963363 733 28.963363'></polyline>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Reports
                  </NavLink>
                  {(user.type === "Admin" || user.type === "Super") && (
                    <NavLink className='nav-link' exact to='/settings'>
                      <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1'>
                        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                          <g transform='translate(-821.000000, -17.000000)' stroke='currentColor' strokeWidth='2'>
                            <g transform='translate(822.000000, 18.000000)'>
                              <line x1='10' y1='20' x2='10' y2='0' id='Line'></line>
                              <line x1='20' y1='10' x2='0' y2='10' id='Line'></line>
                              <line x1='17.0710678' y1='2.92893219' x2='2.92893219' y2='17.0710678' id='Line'></line>
                              <line x1='2.92893219' y1='2.92893219' x2='17.0710678' y2='17.0710678' id='Line'></line>
                              <circle id='Oval' fill='#FFFFFF' cx='10' cy='10' r='6.5'></circle>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Settings
                    </NavLink>
                  )}
                  {user.type === "Admin" && (
                    <NavLink className='nav-link payments' to='/payments'>
                      <PaymentSVG />
                      Payments
                    </NavLink>
                  )}
                </Nav>
              </Navbar.Collapse>
              <Nav className='navbar-right'>
                <NavDropdown
                  className='user-info'
                  title={
                    user.name && (
                      <>
                        <span>{user.name} </span>
                        {/* <span className='mobile-hide'>&nbsp;{lastName()}</span> */}
                      </>
                    )
                  }
                >
                  <NavLink exact className='dropdown-item' to='/accounts'>
                    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-1103.000000, -69.000000)' stroke='currentColor' strokeWidth='1.5'>
                          <g transform='translate(1086.000000, 52.000000)'>
                            <g transform='translate(17.000000, 17.000000)'>
                              <circle id='Oval' cx='7' cy='3.68421053' r='2.93421053'></circle>
                              <path
                                d='M7,8.11842105 C8.63510726,8.11842105 10.118401,8.70211929 11.1909616,9.65550649 C12.0420586,10.4120371 12.6334816,11.403215 12.8193475,12.5131579 L12.8193475,12.5131579 L1.18065249,12.5131579 C1.36651838,11.403215 1.95794145,10.4120371 2.80903844,9.65550649 C3.88159903,8.70211929 5.36489274,8.11842105 7,8.11842105 Z'
                                id='Oval-Copy'
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    My Account
                  </NavLink>
                  <NavDropdown.Divider />
                  {(user.type === 'Admin') &&
                    <>
                      <NavLink exact className='dropdown-item' to='/billing'>
                        <svg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'>
                          <g fill='none' fill-rule='evenodd'>
                            <path d='m0 0h15v15h-15z' />
                            <path
                              d='m11.875 8.75v-5c0-.6875-.5625-1.25-1.25-1.25h-8.75c-.6875 0-1.25.5625-1.25 1.25v5c0 .6875.5625 1.25 1.25 1.25h8.75c.6875 0 1.25-.5625 1.25-1.25zm-1.25 0h-8.75v-5h8.75zm-4.375-4.375c-1.0375 0-1.875.8375-1.875 1.875s.8375 1.875 1.875 1.875 1.875-.8375 1.875-1.875-.8375-1.875-1.875-1.875zm8.125 0v6.875c0 .6875-.5625 1.25-1.25 1.25h-10.625c0-.625 0-.5625 0-1.25h10.625v-6.875z'
                              fill='#979797'
                              fill-rule='nonzero'
                            />
                          </g>
                        </svg>
                        Billing
                      </NavLink>
                      <NavDropdown.Divider />
                    </>
                  }

                  {(user.type === 'Admin') &&
                    <>
                      <a exact className='dropdown-item' href="https://evaa.app/support/" target={'_blank'}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 18 18"
                        >
                          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                            <g transform="translate(-1102 -164)">
                              <g transform="translate(1086 52)">
                                <g transform="translate(17 113)">
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="8"
                                    stroke="#979797"
                                    strokeWidth="2"
                                  ></circle>
                                  <text
                                    fill="#8A8989"
                                    fontFamily="SFProText-Semibold, SF Pro Text"
                                    fontSize="12"
                                    fontWeight="500"
                                  >
                                    <tspan x="5" y="12">
                                      ?
                                    </tspan>
                                  </text>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        Help
                      </a>
                      <NavDropdown.Divider />
                    </>
                  }

                  <NavDropdown.Item onClick={handleLogout}>
                    <svg width='15px' height='12px' viewBox='0 0 15 12' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-1103.000000, -119.000000)' fill='currentColor'>
                          <g transform='translate(1086.000000, 52.000000)'>
                            <g transform='translate(17.000000, 67.000000)'>
                              <path
                                d='M0,1.42 C0,0.6357553 0.6357553,0 1.42,0 L7.81,0 C8.594266,0 9.23,0.6357553 9.23,1.42 L9.23,2.84 C9.23,3.2321259 8.912133,3.55 8.52,3.55 C8.127867,3.55 7.81,3.2321188 7.81,2.84 L7.81,1.42 L1.42,1.42 L1.42,9.94 L7.81,9.94 L7.81,8.52 C7.81,8.127867 8.127867,7.81 8.52,7.81 C8.912133,7.81 9.23,8.127867 9.23,8.52 L9.23,9.94 C9.23,10.724266 8.594266,11.36 7.81,11.36 L1.42,11.36 C0.6357553,11.36 0,10.724266 0,9.94 L0,1.42 Z M10.857959,3.0479519 C11.135214,2.7706827 11.584786,2.7706827 11.862041,3.0479519 L13.992041,5.177959 C14.269296,5.455214 14.269296,5.904786 13.992041,6.182041 L11.862041,8.312041 C11.584786,8.589296 11.135214,8.589296 10.857959,8.312041 C10.580704,8.034786 10.580704,7.585214 10.857959,7.307959 L11.775918,6.39 L4.97,6.39 C4.5778812,6.39 4.26,6.072133 4.26,5.68 C4.26,5.287867 4.5778812,4.97 4.97,4.97 L11.775918,4.97 L10.857959,4.0520481 C10.580704,3.7747718 10.580704,3.3252282 10.857959,3.0479519 Z'
                                id='Shape'
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar>
          </div>
        </div>
        {props.children}
      </div>
      <div>
        <div className='container'>
          <PageFooter />
        </div>
      </div>
    </div>
  );
};

export default SiteWrapper;
