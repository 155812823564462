// @flow

import * as React from "react";
import { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import ReactApexChart from "react-apexcharts";
import SiteWrapper from "./SiteWrapper";
import * as userService from "./store/services/userService";
import * as stationService from "./store/services/stationsServices";
import * as uptimeService from "./store/services/uptimeServices";
import * as billService from "./store/services/billServices";
import GoogleMap from "./GoogleMap";
import WebSocketClient from "./components/WebSocketClient";
import Svgicon from "./components/SvgIcon";
import {FRONTENDURL} from './store/services/type';

function Home(props) {
  const [alert, setAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [payoutAlert, setPayoutAlert] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [percent, setPercent] = useState(0);
  const [usersParams, setUsersParams] = useState(7);
  const [uptimeParams, setUptimeParams] = useState(7);
  const [downtimeParams, setDowntimeParams] = useState(7);
  const [users, setUsers] = useState([]);
  const [stations, setStations] = useState([]);
  const [uptime, setUptime] = useState({
    percent: 0,
    ratio: 0,
  });
  const [downtime, setDowntime] = useState({
    percent: 0,
    ratio: 0,
  });
  const [locations, setLocations] = useState([]);
  const [activeStations, setActiveStations] = useState([]);

  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: "bar",
        fontFamily: "inherit",
        height: 40.0,
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },

      grid: {
        strokeDashArray: 4,
      },
      xaxis: {
        labels: {
          padding: 0,
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        labels: {
          padding: 4,
          formatter: function (value) {
            return value + "";
          },
        },
      },
      labels: [],
      colors: ["#000000"],
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: "Users",
        data: [],
      },
    ],
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
    stationService.getDashboardStations().then((response) => {
      setStations(response.data.stations);
      setLocations(response.data.locations);
    });
    billService.checkSubscription()
      .then((res) => {
        if (res.data.sub === 'canceled' || res.data.sub === 'failed') {
          setShowAlert(res.data.sub);
        }

        if (res.data.payout === 'failed') {
          setPayoutAlert(true);
        }
      })
      .catch((error) => {
      });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    userService
      .getReports(usersParams)
      .then((response) => {
        setUsers(response.data.users);
        setTotalUsers(response.data.period_users);
        setPercent(response.data.percent);
        let xaxis = [];
        let yaxis = [];
        response.data.chart_data.map((item) => {
          xaxis.push(item.date);
          yaxis.push(item.count);
        });
        setChartOptions({
          ...chartOptions,
          ...{
            options: { labels: xaxis },
            series: [{ data: yaxis }],
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [usersParams]);

  React.useEffect(() => {
    uptimeService
      .getUptimeReports({ period: uptimeParams })
      .then((response) => {
        setUptime({
          percent: response.data.period_percent,
          ratio: response.data.ratio_percent,
        });
      });
  }, [uptimeParams]);

  React.useEffect(() => {
    uptimeService
      .getUptimeReports({ period: downtimeParams })
      .then((response) => {
        setDowntime({
          percent: 100 - Math.floor(response.data.period_percent),
          ratio: response.data.ratio_percent,
        });
      });
  }, [downtimeParams]);
  React.useEffect(() => {
    let temp = stations.map((item) => {
      item.active = false;
      const station = activeStations.filter((station) => {
        return item.id == station.id;
      });
      if (station.length > 0) {
        item.active = true;
      }
      return item;
    });
    let errorCount = temp.filter((item) => {
      return !item.active;
    });
    setAlert(errorCount);
    setStations(temp);
  }, [activeStations]);
  console.log(alert)
  return (
    <SiteWrapper loading={loading}>
      <Page.Content>

        {
          showAlert ?
            showAlert === 'canceled' ?
            <Alert type='danger-chargebox' icon='x-circle'>
              Your subscription has been cancelled. Please go the billing screen and select a package to reactivate your subscription.
            </Alert> :
            <Alert type='danger-chargebox' icon='x-circle'>
              <span>
                Your subscription is currently inactive. You will no longer be able to access the software from the end of the current billing cycle. <a href={FRONTENDURL+"/billing/plan"}>Click here</a> to update your subscription and continue using Evaa.
              </span>
            </Alert>:null
        }
        {alert.length > 0 ? (
          <Alert type='danger-chargebox' icon='x-circle'>
            Charge Point is down!
          </Alert>
        ) : null
        }
        {payoutAlert &&
          <Alert type='danger-chargebox' icon='x-circle' onClick={() => setPayoutAlert(false)}>
            A payout to your bank has failed. Please update your account details on the payment settings page. If you think this is an error then please contact Evaa support.
          </Alert>
        }
        <div className="page-header">
          <h1 className="page-title d-flex w-100 justify-content-between mb-2">
            Dashboard
          </h1>
        </div>
        <Grid.Row cards={true}>
          <Grid.Col width={12} lg={4}>
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="subheader">PERCENTAGE UPTIME</div>
                  <div className="ml-auto lh-1 ">
                    <NavDropdown
                      alignRight
                      muted
                      title={`Last ${uptimeParams > 30
                        ? uptimeParams / 30 + " months"
                        : uptimeParams + " days"
                        }`}
                      className='dashboard-dropdown user-info'
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          setUptimeParams(7);
                        }}
                      >
                        Last 7 days
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          setUptimeParams(30);
                        }}
                      >
                        Last 30 days
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          setUptimeParams(90);
                        }}
                      >
                        Last 3 months
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="d-flex mb-5 mt-2 align-items-baseline">
                  <div className="h1 mb-3">{Math.floor(uptime.percent)}%</div>
                  <div className="ml-2">
                    {uptime.ratio > 0 && (
                      <span className="text-green d-inline-flex align-items-center lh-1">
                        {Math.floor(uptime.ratio)}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-640.000000, -207.000000)"
                              stroke="#000000"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(530.000000, 148.000000)">
                                <g transform="translate(88.000000, 55.000000)">
                                  <g transform="translate(24.000000, 5.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                    {uptime.ratio < 0 && (
                      <span className="text-red d-inline-flex align-items-center lh-1">
                        {Math.floor(Math.abs(uptime.ratio))}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-1018.000000, -282.000000)"
                              stroke="#FF4747"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(930.000000, 222.000000)">
                                <g transform="translate(66.000000, 55.000000)">
                                  <g transform="translate(32.500000, 12.000000) scale(1, -1) translate(-32.500000, -12.000000) translate(24.000000, 6.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
                <Progress size="xs">
                  <Progress.Bar color="green" width={uptime.percent} />
                </Progress>
              </div>
            </div>
          </Grid.Col>
          <Grid.Col width={12} lg={4}>
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="subheader">PERCENTAGE DOWNTIME</div>
                  <div className="ml-auto lh-1">
                    <NavDropdown
                      alignRight
                      muted
                      title={`Last ${downtimeParams > 30
                        ? downtimeParams / 30 + " months"
                        : downtimeParams + " days"
                        }`}
                      className='dashboard-dropdown'
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          setDowntimeParams(7);
                        }}
                      >
                        Last 7 days
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          setDowntimeParams(30);
                        }}
                      >
                        Last 30 days
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          setDowntimeParams(90);
                        }}
                      >
                        Last 3 months
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="d-flex mb-5 mt-2 align-items-baseline">
                  <div className="h1 mb-3">{stations.length > 0 ? Math.floor(downtime.percent) : 0}%</div>
                  <div className="ml-2">
                    {downtime.ratio > 0 && (
                      <span className="text-green d-inline-flex align-items-center lh-1">
                        {Math.floor(downtime.ratio)}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-640.000000, -207.000000)"
                              stroke="#000000"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(530.000000, 148.000000)">
                                <g transform="translate(88.000000, 55.000000)">
                                  <g transform="translate(24.000000, 5.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                    {downtime.ratio < 0 && (
                      <span className="text-red d-inline-flex align-items-center lh-1">
                        {Math.floor(downtime.ratio)}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-1018.000000, -282.000000)"
                              stroke="#FF4747"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(930.000000, 222.000000)">
                                <g transform="translate(66.000000, 55.000000)">
                                  <g transform="translate(32.500000, 12.000000) scale(1, -1) translate(-32.500000, -12.000000) translate(24.000000, 6.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
                <Progress size="xs">
                  <Progress.Bar color="red" width={stations.length > 0 ? downtime.percent : 0} />
                </Progress>
              </div>
            </div>
          </Grid.Col>
          <Grid.Col width={12} lg={4}>
            <div className="card h-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="subheader">TOTAL USERS</div>
                  <div className="ml-auto lh-1">
                    <NavDropdown
                      alignRight
                      muted
                      title={`Last ${usersParams > 30
                        ? usersParams / 30 + " months"
                        : usersParams + " days"
                        }`}
                      className='dashboard-dropdown'
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          setUsersParams(7);
                        }}
                      >
                        Last 7 days
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          setUsersParams(30);
                        }}
                      >
                        Last 30 days
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          setUsersParams(90);
                        }}
                      >
                        Last 3 months
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="d-flex  mt-2 align-items-baseline">
                  <div className="h1 mb-3">{totalUsers}</div>
                  <div className="ml-2">
                    {percent > 0 && (
                      <span className="text-green d-inline-flex align-items-center lh-1">
                        {Math.floor(percent)}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-640.000000, -207.000000)"
                              stroke="#000000"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(530.000000, 148.000000)">
                                <g transform="translate(88.000000, 55.000000)">
                                  <g transform="translate(24.000000, 5.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                    {percent < 0 && (
                      <span className="text-red d-inline-flex align-items-center lh-1">
                        {Math.floor(Math.abs(percent))}%
                        <svg
                          width="21px"
                          height="14px"
                          viewBox="0 0 21 14"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                          >
                            <g
                              transform="translate(-1018.000000, -282.000000)"
                              stroke="#FF4747"
                              strokeWidth="1.5"
                            >
                              <g transform="translate(930.000000, 222.000000)">
                                <g transform="translate(66.000000, 55.000000)">
                                  <g transform="translate(32.500000, 12.000000) scale(1, -1) translate(-32.500000, -12.000000) translate(24.000000, 6.000000)">
                                    <polyline points="9.19132392e-15 11.0898437 4.08984375 7 8.13476563 10.0449219 13.2460938 3.93359375"></polyline>
                                    <polyline
                                      transform="translate(12.922870, 4.323223) scale(1, -1) rotate(-45.000000) translate(-12.922870, -4.323223) "
                                      points="9.42287045 2.5732233 12.9228704 6.0732233 16.4228704 2.5732233"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
                <ReactApexChart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="bar"
                  height={40}
                ></ReactApexChart>
              </div>
            </div>
          </Grid.Col>

          <Grid.Col lg={6}>
            <Card className='dashboard-card'>
              <Card.Header className="d-flex justify-content-between">
                <div className='d-flex align-items-center'>
                  <Svgicon name='cp' />
                  <Card.Title>Charge Point Summary</Card.Title>
                </div>
                <Button
                  color="secondary"
                  onClick={() => {
                    props.history.push("/charge-points");
                  }}
                >
                  View All
                </Button>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table card-table table-vcenter datatable border-bottom-0"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>ID</Table.ColHeader>
                    <Table.ColHeader>LOCATION</Table.ColHeader>
                    <Table.ColHeader>UPTIME</Table.ColHeader>
                    <Table.ColHeader>DOWNTIME</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {stations.map((station, key) => (
                    <Table.Row
                      key={key}
                      className={station.active ? "" : "down"}
                    >
                      <Table.Col>{station.id}</Table.Col>
                      <Table.Col className="text-nowrap mobile-locations">
                        {station.street}
                      </Table.Col>
                      <Table.Col>
                        {station.total_count
                          ? Math.floor(
                            (station.up_count / station.total_count) * 100
                          ).toFixed(0)
                          : 0}
                        %
                      </Table.Col>
                      <Table.Col>
                        {station.total_count
                          ? (
                            100 -
                            Math.floor(
                              (station.up_count / station.total_count) * 100
                            )
                          ).toFixed(0)
                          : 100}
                        %
                      </Table.Col>
                      <Table.Col>
                        <Button
                          color="secondary"
                          onClick={() => {
                            props.history.push("/charge-points/" + station.id);
                          }}
                        >
                          View
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
          <Grid.Col lg={6}>
            <Card className='dashboard-card'>
              <Card.Header className="d-flex justify-content-between">
                <div className='d-flex align-items-center'>
                  <Svgicon name='user' />
                  <Card.Title>Users</Card.Title>
                </div>
                <Button
                  color="secondary"
                  onClick={() => {
                    props.history.push("/users");
                  }}
                >
                  View All
                </Button>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table card-table table-vcenter datatable border-bottom-0"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Name</Table.ColHeader>
                    <Table.ColHeader>Role</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {users.map((user, key) => (
                    <Table.Row key={key}>
                      <Table.Col>{user.name}</Table.Col>
                      <Table.Col className="text-nowrap">{user.type}</Table.Col>
                      <Table.Col>
                        <Button
                          color="secondary"
                          onClick={() => {
                            props.history.push(`users/${user.id}`);
                          }}
                        >
                          Edit
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Body className="p-0">
                <div
                  style={{
                    height: "545px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div className='map-title'>
                    <Svgicon name='pin' />
                    <span>Locations</span>
                  </div>
                  <GoogleMap stations={locations} />
                </div>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
      {stations.length > 0 && (
        <WebSocketClient setActiveStations={setActiveStations} />
      )}
    </SiteWrapper>
  );
}

export default Home;
