import axios from "axios";
import { BASE_URL } from "./type";

export function getSiteById(id, params) {
  return axios.get(BASE_URL + "sites/" + id, { params });
}
export function updateSite(id, data) {
  return axios.put(BASE_URL + "sites/" + id, data);
}
export function createSite(data) {
  return axios.post(BASE_URL + "sites", data);
}
export function deleteSite(id) {
  return axios.delete(BASE_URL + "sites/" + id);
}
