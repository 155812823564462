// @flow

import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import SiteWrapper from "../../SiteWrapper";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import * as disputeService from "../../store/services/disputeServices";
import StripePaymentNav from "./StripePaymentNav";
import Svgicon from "../../components/SvgIcon";
import moment from "moment";
import { BACKENDURL } from "../../store/services/type";
const formSchema = Yup.object().shape({
  customer_communication: Yup.mixed().required("Customer communication file is required"),
  receipt_file: Yup.mixed().required("Receipt file is required"),
  product_description: Yup.string().required("Product description is required"),
});
function DisputesPage(props) {
  const [alert, setAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disputes, setDisputes] = useState([]);
  const [opend, setOpend] = useState(0);
  const [closed, setClosed] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [filterParams, setParams] = useState({
    page: 1,
    prev: null,
    next: null,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  React.useEffect(() => {
    disputeService
      .getDisputesDashboard()
      .then((response) => {
        setOpend(response.data.opend);
        setClosed(response.data.closed);
      })
      .catch((error) => { });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    disputeService
      .getDisputes(filterParams)
      .then((response) => {
        setDisputes(response.data.disputes.data);
        setHasMore(response.data.disputes.has_more);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [filterParams]);

  return (
    <SiteWrapper loading={loading}>
      <StripePaymentNav />
      <Page.Content>
        <div className='page-header'>
          <h1 className='page-title d-flex w-100 justify-content-between mb-2'>Disputes</h1>
        </div>
        <Grid.Row className='billing-cards' cards={true}>
          <Grid.Col width={12} lg={6}>
            <div className='card h-100'>
              <div className='card-body'>
                <div className='d-flex align-items-center'>
                  <div className='subheader'>OPEN DISPUTES</div>
                </div>
                <div className='d-flex mb-5 mt-5 align-items-baseline'>
                  <div className='h1 mb-3'>{opend}</div>
                </div>
              </div>
            </div>
          </Grid.Col>
          <Grid.Col width={12} lg={6}>
            <div className='card h-100'>
              <div className='card-body'>
                <div className='d-flex align-items-center'>
                  <div className='subheader'>CLOSED DISPUTES</div>
                </div>
                <div className='d-flex mb-5 mt-5 align-items-baseline'>
                  <div className='h1 mb-3'>{closed}</div>
                </div>
              </div>
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Svgicon name='dispute' />
                <Card.Title>Disputes</Card.Title>
              </Card.Header>
              <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable'>
                <Table.Header>
                  <Table.Row>
                    {/* <Table.ColHeader>ID</Table.ColHeader> */}
                    <Table.ColHeader>Customer Name</Table.ColHeader>
                    {/* <Table.ColHeader>Customer Email</Table.ColHeader> */}
                    <Table.ColHeader>Reason</Table.ColHeader>
                    <Table.ColHeader>EVIDENCE DUE BY</Table.ColHeader>
                    <Table.ColHeader>HAS EVIDENCE</Table.ColHeader>
                    <Table.ColHeader>Charge Id</Table.ColHeader>
                    <Table.ColHeader>STATUS</Table.ColHeader>
                    <Table.ColHeader>CHARGE POINT ID</Table.ColHeader>
                    <Table.ColHeader>Amount</Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {disputes.map((item, i) => (
                    <Table.Row key={i}>
                      {/* <Table.Col>{item.id}</Table.Col> */}
                      <Table.Col className='text-nowrap'>{item.evidence.customer_name}</Table.Col>
                      {/* <Table.Col>{item.evidence.customer_email_address}</Table.Col> */}
                      <Table.Col className='text-capitalize'>{item.reason}</Table.Col>
                      <Table.Col>
                        <Moment unix format='HH:mm DD MMM YYYY'>
                          {item.evidence_details.due_by}
                        </Moment>
                      </Table.Col>
                      <Table.Col>{item.evidence_details.has_evidence ? "True" : "False"}</Table.Col>
                      <Table.Col>{item.charge}</Table.Col>
                      <Table.Col>{item.status}</Table.Col>
                      <Table.Col className='text-capitalize'>{item.station_id ? item.station_id : ''}</Table.Col>
                      <Table.Col className='price'>£{(item.amount / 100).toFixed(2)}</Table.Col>
                      <Table.Col>
                        <Button
                          color='secondary'
                          onClick={() => {
                            props.history.push("/payments/disputes/" + item.id);
                          }}
                        >
                          View
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {disputes.length === 0 && <h4 className='h4 mt-4 mb-4 text-center p-6'>{!loading && "No disputes found"}</h4>}
              {disputes.length > 0 && (
                <div className='pagination-barlows'>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setCurrentPage(currentPage - 1);

                      setParams({
                        ...filterParams,
                        ...{ page: currentPage - 1, prev: disputes[0].id, next: null },
                      });
                    }}
                    disabled={currentPage === 1 || loading}
                  >
                    Prev
                  </Button>

                  <span>{currentPage}</span>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage + 1, next: disputes[29].id, prev: null },
                      });
                    }}
                    disabled={(!hasMore && disputes.length !== 30) || loading}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }
    return <img src={thumb} alt={file.name} className='img-thumbnail mt-2' height={200} width={200} />;
  }
}
function UpdateDisputes(props) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [alert_update, setUpdateAlert] = useState({});
  const [disputes, setDisputes] = useState({});
  const customerFileInput = useRef(null);
  const receiptFileInput = useRef(null);
  const scrollRef = useRef(null);
  const [initialValues, setInitialValues] = useState({
    customer_communication: null,
    receipt_file: null,
    uncategorized_text: "",
    product_description: "",
  });
  const handleSubmit = (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("customer_communication", values.customer_communication);
    formData.append("receipt_file", values.receipt_file);
    formData.append("uncategorized_text", values.uncategorized_text);
    formData.append("product_description", values.product_description);
    disputeService
      .updateDisputes(props.match.params.id, formData)
      .then((response) => {
        setUpdateAlert({
          type: "success",
          message: response.data.message,
        });
        setLoading(false);
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        setUpdateAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    disputeService
      .getDisputesById(props.match.params.id)
      .then((response) => {
        setLoading(false);
        setDisputes(response.data.disputes);
        setInitialValues({
          customer_communication: response.data.customer_communication ? BACKENDURL+'/public/temp/' + response.data.customer_communication.filename : null,
          receipt_file: response.data.receipt ? BACKENDURL+'/public/temp/' + response.data.receipt.filename : null,
          product_description: response.data.disputes.evidence.product_description,
          uncategorized_text: response.data.disputes.evidence.uncategorized_text ? response.data.disputes.evidence.uncategorized_text : '',
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  const handleCustomerFileUpload = () => {
    customerFileInput.current.click();
  };
  const handleReceiptFileUpload = () => {
    receiptFileInput.current.click();
  };
  const handleCloseDisputes = () => {
    setLoading(true);
    disputeService
      .closeDisputes(props.match.params.id)
      .then((response) => {
        setLoading(false);
        window.scrollTo(0, 0)
        setAlert({
          type: "success",
          message: response.data.message,
        });
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
      });
  };
  const handleCancel = () => {
    // scrollRef.current.scrollTo(0, 0)
    // window.scrollTo(0, 0)
    props.history.push("/payments/disputes")
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, { setSubmitting, setErrors /* setValues and other goodies */ }) => {
        handleSubmit(values);
      }}
      render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <SiteWrapper loading={isLoading}>
          <StripePaymentNav />
          <Page.Content>
            {alert.message && alert.type === 'success' && <Alert type={alert.type}>{alert.message}</Alert>}
            {alert_update.message && alert_update.type === 'success' && <Alert type={alert_update.type}>{alert_update.message}</Alert>}
            <Page.Header title='Disputes' />
            <Grid.Row cards={true}>
              <Grid.Col width={12}>
                <Card className='charge-point-card'>
                  <form onSubmit={handleSubmit} autoComplete='off'>
                    <Grid.Row>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Header>
                          <Svgicon name='dispute' />
                          <Card.Title>Submit Evidence</Card.Title>
                          <div className="ml-auto lh-1">
                            <Button
                              color="secondary"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Card.Header>
                      </Grid.Col>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Body className='pt-40 pb-40'>
                          <Grid.Row>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>DATE</div>
                              <div className='details'>
                                {disputes.created && (
                                  <Moment unix format='DD MMM YYYY'>
                                    {disputes.created}
                                  </Moment>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>CUSTOMER NAME</div>
                              <div className='details'>{disputes.evidence && disputes.evidence.customer_name}</div>
                            </Grid.Col>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>CUSTOMER EMAIL</div>
                              <div className='details'>{disputes.evidence && disputes.evidence.customer_email_address}</div>
                            </Grid.Col>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>REASON</div>
                              <div className='details'>{disputes.reason}</div>
                            </Grid.Col>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>AMOUNT</div>
                              <div className='details'>{disputes.amount && "£" + (disputes.amount / 100).toFixed(2)}</div>
                            </Grid.Col>
                            {/* </Grid.Row>
                          <Grid.Row className='mb-5'> */}
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>EVIDENCE DUE BY</div>
                              <div className='details'>
                                {disputes.evidence_details && (
                                  <Moment unix format='HH:mm DD MMM YYYY'>
                                    {disputes.evidence_details.due_by}
                                  </Moment>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>HAS EVIDENCE</div>
                              <div className='details'>{disputes.evidence_details && disputes.evidence_details.has_evidence ? "True" : "False"}</div>
                            </Grid.Col>
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>CHARGE ID</div>
                              <div className='details' style={{ marginBottom: 0 }}>{disputes.charge}</div>
                            </Grid.Col>
                            {/* <div style={{ width: 80 }} /> */}
                            <Grid.Col className='disputes-details'>
                              <div className='subheader mb-3'>STATUS</div>
                              <div className='details' style={{ marginBottom: 0 }}>{disputes.status}</div>
                            </Grid.Col>
                          </Grid.Row>
                        </Card.Body>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Header className='d-flex justify-content-between'>
                          <Card.Title>Customer Communication</Card.Title>
                        </Card.Header>
                      </Grid.Col>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Body className='pt-40 pb-40'>
                          <div className='pb-40' style={{ color: '#74797E' }}>
                            Any communication with the customer that you feel is relevant to your case. Examples include emails proving that the
                            customer received the product or service, or demonstrating their use of or satisfaction with the product or service.
                          </div>
                          <div className=''>
                            {values.customer_communication && typeof (values.customer_communication) === 'string' ?
                              <img src={values.customer_communication} style={{ width: 200, height: 100 }} /> :
                              <Thumb file={values.customer_communication} />
                            }
                            <input
                              type='file'
                              name='customer_communication'
                              hidden
                              ref={customerFileInput}
                              className={
                                errors.customer_communication && touched.customer_communication
                                  ? "form-control is-invalid state-invalid"
                                  : "form-control"
                              }
                              onChange={(event) => {
                                setFieldValue("customer_communication", event.currentTarget.files[0]);
                              }}
                            />
                            {errors.customer_communication && <div className='invalid-feedback'>{errors.customer_communication}</div>}
                          </div>
                          <div className=''>
                            <Button color='upload' onClick={handleCustomerFileUpload} type='button'>
                              Upload File
                            </Button>
                          </div>
                        </Card.Body>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Header className='d-flex justify-content-between'>
                          <Card.Title>Receipts</Card.Title>
                        </Card.Header>
                      </Grid.Col>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Body className='pt-40 pb-40'>
                          <div className='pb-40' style={{ color: '#74797E' }}>Any receipt or message sent to the customer notifying them of the charge.</div>
                          <div className=''>
                            {values.receipt_file && typeof (values.receipt_file) === 'string' ?
                              <img src={values.receipt_file} style={{ width: 200, height: 100 }} /> :
                              <Thumb file={values.receipt_file} />
                            }
                            <input
                              type='file'
                              name='receipt_file'
                              hidden
                              ref={receiptFileInput}
                              className={errors.receipt_file && touched.receipt_file ? "form-control is-invalid state-invalid" : "form-control"}
                              onChange={(event) => {
                                setFieldValue("receipt_file", event.currentTarget.files[0]);
                              }}
                            />
                            {errors.receipt_file && <div className='invalid-feedback'>{errors.receipt_file}</div>}
                          </div>
                          <div className=''>
                            <Button color='upload' onClick={handleReceiptFileUpload} type='button'>
                              Upload File
                            </Button>
                          </div>
                        </Card.Body>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Header className='d-flex justify-content-between'>
                          <Card.Title>Product Description</Card.Title>
                        </Card.Header>
                      </Grid.Col>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Body className='pt-40 pb-40'>
                          <div className='pb-40' style={{ color: '#74797E' }}>A description of the product or service that was sold.</div>
                          <div className=''>
                            <textarea
                              rows='5'
                              placeholder='Enter product description here'
                              name='product_description'
                              className={
                                errors.product_description && touched.product_description
                                  ? "w-100 form-control notes-area  is-invalid"
                                  : "w-100 form-control notes-area"
                              }
                              value={values.product_description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                            {errors.product_description && <div className='invalid-feedback'>{errors.product_description}</div>}
                          </div>
                        </Card.Body>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Header className='d-flex justify-content-between'>
                          <Card.Title>Additional Information</Card.Title>
                        </Card.Header>
                      </Grid.Col>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Body className='pt-40 pb-40'>
                          <div className='pb-40' style={{ color: '#74797E' }}>Any additional evidence or statements.</div>
                          <div className='pb-40'>
                            <textarea
                              rows='5'
                              placeholder='Enter additional information here'
                              name='uncategorized_text'
                              className={
                                errors.uncategorized_text && touched.uncategorized_text
                                  ? "w-100 form-control notes-area  is-invalid"
                                  : "w-100 form-control notes-area"
                              }
                              value={values.uncategorized_text}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                          </div>
                          {alert_update.message && alert_update.type !== 'success' && <Alert type={alert_update.type}>{alert_update.message}</Alert>}
                          <div className='text-right'>
                            <Button
                              color='secondary h-38 border-none mr-3'
                              type='button'
                              onClick={() => {
                                props.history.push("/payments/disputes");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button color='barlows h-38' type='submit' disabled={isLoading}>
                              Submit Evidence
                            </Button>
                          </div>
                        </Card.Body>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Header className='d-flex justify-content-between'>
                          <Card.Title>Close Dispute</Card.Title>
                        </Card.Header>
                      </Grid.Col>
                      <Grid.Col width={12} lg={12}>
                        <div className='border-bottom'></div>
                      </Grid.Col>
                      <Grid.Col width={12} lg={8} className='m-auto'>
                        <Card.Body className='pt-40 pb-40'>
                          <div className='pb-40' style={{ color: '#74797e' }}>The dispute will be closed in the customers favour and their funds will be refunded. </div>
                          <div className=''>
                            {alert.message && alert.type !== 'success' && <Alert type={alert.type}>{alert.message}</Alert>}
                            <Button color='secondary' type='button' onClick={handleCloseDisputes} disabled={isLoading}>
                              Close Dispute
                            </Button>
                          </div>
                        </Card.Body>
                      </Grid.Col>
                    </Grid.Row>
                  </form>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </SiteWrapper>

      )}
    />
  );
}
export default DisputesPage;
export { UpdateDisputes };
